export default class UserAgent {
  constructor (headers) {
    this.headers = headers || {}
    this.ua = this.headers['user-agent']
  }

  get isSmartPhone () {
    // 本番環境や black では CloudFront を通すので、CF特有のヘッダを使う
    if (this.headers['CloudFront-Is-Mobile-Viewer'] === 'true') return true
    if (this.headers['CloudFront-Is-Tablet-Viewer'] === 'true') return true
    if (this.headers['CloudFront-Is-Desktop-Viewer'] === 'true') return false

    return /(iPod|iPhone|incognito|webmate|Android|dream|CUPCAKE|froyo|BlackBerry|webOS|s8000|bada|IEMobile|Googlebot-Mobile|AdsBot-Google|MachiConJapan)/.test(this.ua)
  }

  get isNativeApp () {
    return /MachiConJapan/.test(this.ua)
  }

  // Androidかつnativeアプリかつアプリのversion2以上
  get isAndroidAppVersionOver () {
    const uaMatch = this.ua.match(/MachiConJapan\/(\d{1})(.\d{1})*/)
    return this.isNativeApp && this.isAndroid && uaMatch !== null && uaMatch[1] >= 2
  }

  get isTablet () {
    return this.headers['CloudFront-Is-Tablet-Viewer'] === 'true'
  }

  get isPc () {
    return !this.isSmartPhone
  }

  get isIos () {
    return /iP(hone|(o|a)d)/.test(this.ua)
  }

  get isAndroid () {
    return /Android/.test(this.ua)
  }
}
