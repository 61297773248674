<template>
  <div v-show="false" id="google_translate_element" />
</template>
<script>
export default {
  head () {
    return {
      script: [
        { innerHTML: `function googleTranslateElementInit() { new window.google.translate.TranslateElement({ pageLanguage: 'ja', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element'); };`, type: 'text/javascript' },
        { src: 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit' }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  destroyed () {
    const iframe = document.getElementsByClassName('goog-te-banner-frame')[0]
    if (!iframe) return

    const innerDoc = iframe.contentDocument || iframe.contentWindow.document
    const restoreEl = innerDoc.querySelector("button[id$='restore']")
    if (restoreEl) restoreEl.click()
    const closeEl = innerDoc.getElementsByClassName('goog-close-link')
    if (closeEl && closeEl[0]) closeEl[0].click()
  }
}
</script>
