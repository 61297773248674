const INTERESTS = {
  bus: '婚活バスツアー（お見合いバスツアー）',
  cooking: '料理コン',
  survivalgame: 'サバゲーコン',
  anime: 'アニメコン',
  fishing: '釣りコン',
  nazotoki: '謎解きコン',
  travel: '旅コン',
  sports: 'スポーツコン',
  game: 'ゲーム/アニメコン',
  outdoor: 'アウトドアコン',
  futsal: 'フットサルコン',
  tennis: 'テニスコン',
  bouldering: 'ボルダリングコン',
  'interest-other': 'その他',
  'sports-other': 'その他（スポーツコン）',
  cat: '猫コン',
  baseball: '野球コン',
  music: '音楽コン',
  jinro: '人狼コン',
  books: '読書コン',
  soccer: 'サッカーコン',
  karaoke: 'カラオケコン',
  art: '美術館コン',
  train: '鉄道コン',
  drinks: 'お酒コン・出会い',
  commedy: 'お笑いコン',
  camera: 'カメラコン',
  history: '歴史コン',
  game_other: 'その他',
  outdoor_other: 'その他',
  learn_other: 'その他',
  animal_other: 'その他',
  artamuse_other: 'その他',
  artamuse: 'アート/アミューズメントコン',
  learn: '学びコン',
  animal: 'ペット/動物コン',
  tour: '観光体験・ガイドツアー体験',
  sea: '海の体験',
  river: '川・湖の体験',
  mount: '山・自然の体験',
  sky: '空の体験',
  snow: '雪の体験',
  walking: 'ウォーキングコン',
  vegetables: '野菜・果物狩り体験',
  culture: '日本文化コン',
  vehicle: '乗り物コン',
  ceramics: 'ものづくりコン',
  job: '職業体験コン',
  drink: 'お酒コン',
  zoo: '動物園コン',
  dog: '犬コン',
  museum: '美術館コン',
  comedy: 'お笑いコン',
  craft: 'ものづくりコン',
  movie: '映画コン',
  owl: 'ふくろうコン',
  rabbit: 'うさぎコン',
  hedgehog: 'はりねずみコン',
  reptiles: '爬虫類コン'
}

const FEATURES = {
  temples: 'お寺や神社コン・コンパ・婚活イベント一覧'
}

const EVENT_TYPE_TEXTS = {
  machicon: '街コン',
  'petit-con': 'プチ街コン',
  party: '恋活パーティー',
  konkatsu: '婚活パーティー・お見合いパーティー',
  jibunmigaki: '自分磨き・セミナー',
  activity: '体験コン・アクティビティー',
  interest: '趣味コン',
  bar_gourmet: 'バル・グルメイベント',
  other: 'その他'
}

export default class BreadCrumb {
  static getInterestText (type) {
    return INTERESTS[type] || ''
  }

  static getFeatureText (featureTag) {
    return FEATURES[featureTag] || ''
  }

  static getEventTypeText (type) {
    return EVENT_TYPE_TEXTS[type] || type
  }
}
