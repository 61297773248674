import PageDetector from '@/utils/PageDetector'
import SearchParams from '@/utils/search/SearchParams'
const PublicRelations = defineAsyncComponent(() => import('@/components/banner/PublicRelations.vue'))

export default {
  components: { PublicRelations },
  props: {
    options: { type: Object, required: false, default: () => ({}) }
  },
  data () {
    return {
      contents: {}
    }
  },
  computed: {
    pageDetector () {
      return new PageDetector(this.$route)
    },
    apiSearchParams () {
      return new SearchParams(this.$store, this.$route).apiSearchParams
    },
    apiSearchQuery () {
      return new SearchParams(this.$store, this.$route).searchSideContentQueryString
    }
  },
  watch: {
    apiSearchQuery: {
      handler () {
        this.fetchSideContents()
      },
      immediate: true
    }
  },
  methods: {
    fetchSideContents () {
      const sideContentUri = `side_contents/${this.apiSearchQuery}`
      const sideContentsState = this.$store.$state.layout_data.side_contents
      if (sideContentsState && sideContentsState.path === sideContentUri) {
        this.contents = sideContentsState.contents
      } else {
        this.$store.dispatch('apiGet', sideContentUri)
          .then(res => {
            this.contents = res.data
            this.updateSideContentState(sideContentUri, this.contents)
          })
      }
    },
    updateSideContentState (sideContentUri, contents) {
      this.$store.LAYOUT_DATA({
        type: 'side_contents',
        data: {
          path: sideContentUri,
          contents: contents
        }
      })
    }
  }
}
