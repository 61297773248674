
export default class CriteoGtmData {
  constructor (store) {
    this.store = store
  }

  fetchData () {
    // if data is already exists in Vuex store, no need to fetch
    if (Object.keys(this.store.$state.criteo_gtm_data).length > 0) return new Promise((resolve, reject) => { resolve({}) })

    return this.store.dispatch('apiGet', 'criteo_gtm_datalayer').then(res => {
      this.store.SET_STATE({ key: 'criteo_gtm_data', value: res.data || {} })
      return res.data
    }).catch(e => ({}))
  }
}
