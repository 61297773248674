<template>
  <div v-if="recentCheckedEvents.length > 0" class="recentCheckedEvents">
    <recommend-event-list v-if="isRecentPcPurchase" :events="recentCheckedEvents" from="recent-checked-events" @click-from-recent-checked-events="sendOpixClickedEvent" />
    <scroll-event-list
      v-else
      :event-list-title="RECENT_CHECKED_EVENT"
      :events="recentCheckedEvents"
      showed-block="recent-checked-events"
      @plus-bookmark-count="plusBookmarkedCount"
      @minus-bookmark-count="minusBookmarkedCount" />
  </div>
</template>

<script>
import { buildQueryString } from '@/utils/url-action'
import { RECENT_CHECKED_EVENT } from '@/utils/constants/commons'
const ScrollEventList = defineAsyncComponent(() => import('@/components/event/ScrollEventList.vue'))
import BrowserStorage from '@/utils/BrowserStorage'
import PageDetector from '@/utils/PageDetector'

const RecommendEventList = defineAsyncComponent(() => import('@/components/event/RecommendEventList.vue'))
// 保存上限（最大3個しか表示しないが、終了イベントが含まれる可能性があるので最大10個保存する）
const STORE_LIMIT = 10
const DEFAULT_DISPLAY_LIMIT = 2

export default {
  components: { RecommendEventList, ScrollEventList },
  props: {
    currentEventId: { type: Number, required: false, default: null },
    displayLimit: { type: Number, required: false, default: DEFAULT_DISPLAY_LIMIT }
  },
  data () {
    return {
      RECENT_CHECKED_EVENT,
      recentCheckedEventsKey: 'recent_checked_events',
      recentCheckedEvents: []
    }
  },
  computed: {
    recentCheckedEventIds () {
      const idsString = this.localStorage.getItem(this.recentCheckedEventsKey)
      return idsString ? idsString.split(',') : []
    },
    localStorage () {
      return new BrowserStorage('localStorage')
    },
    pageDetector () {
      const route = useRoute()
      return new PageDetector(route)
    },
    isRecentPcPurchase () {
      return !this.$store.isSP && this.pageDetector.isPurchasesTopPage
    }
  },
  mounted () {
    this.fetchRecentCheckEvents()
    if (this.currentEventId) this.recordCurrentEventAccess()
  },
  methods: {
    fetchRecentCheckEvents () {
      if (!this.$store.isLoggedIn && this.recentCheckedEventIds.length < DEFAULT_DISPLAY_LIMIT) return

      const params = {
        recent_checked_event_ids: this.recentCheckedEventIds.join(','),
        current_event_id: this.currentEventId,
        limit: this.displayLimit
      }
      this.$store.dispatch('apiGet', `event_recent_checks/${buildQueryString(params)}`).then(res => {
        this.recentCheckedEvents = res.data.events.length >= DEFAULT_DISPLAY_LIMIT ? res.data.events : []
        if (this.$store.isLoggedIn) this.localStorage.removeItem(this.recentCheckedEventsKey)
      })
    },
    recordCurrentEventAccess () {
      if (this.$store.isLoggedIn) {
        const params = {
          current_event_id: this.currentEventId,
          recent_checked_event_ids: this.recentCheckedEventIds
        }
        this.$store.dispatch('postEventRecentCheck', params).then(res => { this.localStorage.removeItem(this.recentCheckedEventsKey) })
      } else {
        // CurrentEventId既に保存されている場合、重複削除を行う
        const uniqIds = this.recentCheckedEventIds ? this.recentCheckedEventIds.filter(eventId => (eventId !== String(this.currentEventId))) : []
        uniqIds.push(this.currentEventId)

        // 保存上限を超えた場合、古い方を削除する
        const eventIds = uniqIds.length > STORE_LIMIT ? uniqIds.slice(uniqIds.length - STORE_LIMIT, uniqIds.length) : uniqIds
        this.localStorage.setItem(this.recentCheckedEventsKey, eventIds.join(','))
      }
    },
    sendOpixClickedEvent (data) {
      this.$emit('click-recent-checked-events', data)
    },
    plusBookmarkedCount () {
      this.$emit('plus-bookmark-count')
    },
    minusBookmarkedCount () {
      this.$emit('minus-bookmark-count')
    }
  }
}
</script>
