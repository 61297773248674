import moment from 'mj-moment'
import { HOLIDAY_LIST } from '@/utils/constants/holidayList'
export const dateRange = (start, end) => {
  const getArray = (current, end, array = []) => {
    if (current > end) return array
    array.push(current)
    return getArray(moment(current).add(1, 'days'), end, array)
  }
  return getArray(moment(start), moment(end))
}

export const eventOrganizedDate = (params, isSimplified = false) => {
  const year = params.year ? `${params.year}年` : ''
  const month = params.month ? `${params.month}月` : ''
  const day = params.day ? `${params.day}日` : ''
  return isSimplified ? `${year}${month}${day}` : `${year}${month}${day}開催`
}

export const isHoliday = (params) => {
  return HOLIDAY_LIST.includes(params.format('YYYY-MM-DD'))
}

export const isWeekendDay = (params) => {
  // isoWeekday will return weekday with 1 being Monday and 7 being Sunday. Weekend days are Friday (special for 1on1 service), Saturday and Sunday
  return [5, 6, 7].includes(params.isoWeekday())
}

export const isWeekDay = (params) => {
  // isoWeekday will return weekday with 1 being Monday and 7 being Sunday. Week days are Monday, Tuesday, Wednesday, Thursday and Friday
  return [1, 2, 3, 4, 5].includes(params.isoWeekday())
}

export const today = () => moment().format('YYYY/MM/DD')

export const tomorrow = () => moment().add(1, 'day').format('YYYY/MM/DD')

export const currentYear = () => moment().year()
