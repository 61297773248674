export const NOINDEX_PAGE_NAME = [
  'biz_inquiry',
  'cl_events',
  'cl_events-dating_events',
  'cl_events-dating_events_id',
  'g_questionnaires-page',
  'information-page',
  'marriages',
  'marriages-page',
  'medium',
  'medium-page',
  'push_notification',
  'biz_inquiry-thanked_inquiry',
  'check_in_tickets-receive',
  'restaurant_inquiry-thanked_inquiry',
  'subscriptions-id',
  'subscriptions-auto_renew-subscription_id',
  'subscriptions-auto_renew-subscription_id-cancellation_complete',
  'surveys-id',
  'event-bookmarks-guest',
  'search-page',
  'similar-events',
  'similar-events-week',
  'areas-afs',
  'areas-afs-page',
  'categories-interests',
  'categories-interests-page',
  'areas-interests-activity',
  'areas-interests-activity-page',
  'areas-categories-interests',
  'areas-categories-interests-page',
  'regions-interests-activity',
  'regions-interests-activity-page',
  'regions-categories-interests',
  'regions-categories-interests-page',
  'dates-interests-activity',
  'dates-interests-activity-page',
  'dates-categories-interests',
  'dates-categories-interests-page',
  'dates-areas-interests-activity',
  'dates-areas-interests-activity-page',
  'dates-areas-categories-interests',
  'dates-areas-categories-interests-page',
  'dates-regions-interests-activity',
  'dates-regions-interests-activity-page',
  'interests-activity',
  'interests-activity-page'
]

export const NOINDEX_PATH = [
  '/salessupport/',
  '/idol_con/'
]
