<template>
  <div v-if="contents.interest && contents.interest.list.length > 0 && !pageDetector.isInterestEventsPage" >
    <h3 v-if="contents.interest.title" class="commonHeadingBlack marginT5">{{ contents.interest.title }}</h3>
    <ul class="tagList">
      <li v-for="(listItem, i) in contents.interest.list" :key="i" class="tagList-item">
        <custom-nuxt-link :to="listItem.link" class="tagList-item-link btn-tag">{{ listItem.name }}</custom-nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import SideContentMixin from '@/utils/mixins/SideContent'
export default {
  mixins: [SideContentMixin]
}
</script>

<style scoped lang="scss">
.tagList {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
</style>
