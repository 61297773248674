import AdvertisementData from '@/utils/AdvertisementData'
import { useMainStore } from '@/store'
import { showError, callWithNuxt } from 'nuxt/app'

const fetchNotFoundPageData = async (store) => {
  const state = store.$state
  const requests = []

  // fetch ads if in store is not exists
  if (!state.layout_data.ads) {
    const params = { featured: true, recommendations: true, tie_ups: true }
    const adsRequest = new AdvertisementData(store, params).fetchData().then(data => {
      store.LAYOUT_DATA({ type: 'ads', data: data.ads })
    })
    requests.push(adsRequest)
  }

  // fetch side content if in store is not exists or not match
  if (!state.layout_data.side_contents || state.layout_data.side_contents.path !== 'side_contents/') {
    const sideContentUri = 'side_contents/'
    const sideContentsRes = store.dispatch('apiGet', sideContentUri).then(res => {
      store.LAYOUT_DATA({
        type: 'side_contents',
        data: {
          path: sideContentUri,
          contents: res.data
        }
      })
    })
    requests.push(sideContentsRes)
  }

  if (requests.length === 0) return

  const res = await Promise.all(requests)
  return res
}

const errorHandler = async (store, errorCode) => {
  store.SET_STATE({ key: 'error_code', value: errorCode })
  if (errorCode === 404) {
    await fetchNotFoundPageData(store)
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  // inject errorHandler function to Nuxt context to use global
  return {
    provide: {
      error: async (errorCode) => {
        const store = useMainStore()
        callWithNuxt(nuxtApp, async () => await errorHandler(store, errorCode))
        showError({ statusCode: errorCode })
      }
    }
  }
})
