const GENDERS = {
  female: {
    value: 0,
    text: '女性',
    youngText: '女子'
  },
  male: {
    value: 1,
    text: '男性',
    youngText: '男子'
  }
}

export default class User {
  static getGenderText (gender) {
    return GENDERS[gender] ? GENDERS[gender].text : ''
  }

  static getReverseGender (gender) {
    if (GENDERS[gender]) return gender === 'female' ? 'male' : 'female'
    return ''
  }

  static getGenderTextByValue (value) {
    const key = Object.keys(GENDERS).find(k => GENDERS[k].value === Number(value))
    return key ? GENDERS[key].text : ''
  }

  static getGenderValue (gender) {
    return GENDERS[gender] ? String(GENDERS[gender].value) : null
  }

  static findGenderByValue (val) {
    return Object.keys(GENDERS).find(v => GENDERS[v].value === Number(val))
  }

  static getReverseGenderText (gender) {
    const reverseGender = this.getReverseGender(gender)
    return GENDERS[reverseGender] ? GENDERS[reverseGender].text : ''
  }

  static getReverseGenderYoungText (gender) {
    const reverseGender = this.getReverseGender(gender)
    return GENDERS[reverseGender] ? GENDERS[reverseGender].youngText : ''
  }
}
