// Build query string for url
// params format
// Object {
// param_name: value
// }
// Ex: params = { code: 'abc', 'test': 'true', 'hoge': null }
// ---> Return query string: '?code=abc&test=true'

import { WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY, URL_PARAMS_DEFAULT_PRIORITY } from '@/utils/constants/whitelistNoIndexSearch'

export const buildQueryString = params => {
  const queryKeys = Object.keys(params)
    .filter(key => params[key] && (!(params[key] instanceof Array) || params[key].length > 0))
    .sort((a, b) => {
      const validA = urlPriority(a)
      const validB = urlPriority(b)
      return validA - validB
    })
  const queryString = queryKeys.map(key => {
    if (!(params[key] instanceof Array)) return `${key}=${encodeURIComponent(params[key])}`
    return params[key].filter(v => v).map(v => `${key}=${encodeURIComponent(v)}`).join('&')
  }).join('&')
  return queryString ? `?${queryString}` : ''
}

const urlPriority = url => {
  return WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY[url] ?? URL_PARAMS_DEFAULT_PRIORITY
}

export const addTrailingSlash = path => {
  if (!path.includes('?') && !path.includes('#')) return /\/$/.test(path) ? path : `${path}/`

  const characters = []
  for (let i = 0; i < path.length; i++) {
    if ((path[i] === '?' || path[i] === '#') && (characters.length === 0 || characters[characters.length - 1] !== '/')) {
      characters.push(`/`)
    }
    characters.push(path[i])
  }
  return characters.join('')
}
