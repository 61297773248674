<template>
  <div :class="{ contentBlock: $store.isSP }">
    <ul class="iveryList">
      <li v-for="(article, i) in content.articles" :key="i" class="iveryList-item">
        <a :href="article.url" class="iveryItem">
          <div class="iveryItem-img"><common-lazy-load-image :image-url="article.thumbnail" :alt="article.title" /></div>
          <div class="iveryItem-content">
            <ul class="tagList"><li class="tagList-item">{{ tagTitle }}</li></ul>
            <p class="iveryItem-content-body">{{ article.title }}</p>
            <div class="iveryItem-content-date"><icon far="fa-clock" />{{ formatPostDate(article.post_date) }}</div>
          </div>
        </a>
      </li>
    </ul>
    <div :class="seeMoreBtnWrapperClasses">
      <a :href="content.show_more_link" class="commonButton ivery-show-more btn-secondary-40">{{ showMoreText }}</a>
    </div>
  </div>
</template>

<script>
import { timeConverter } from '@/utils/converter'
const CommonLazyLoadImage = defineAsyncComponent(() => import('@/components/CommonLazyLoadImage.vue'))
export default {
  components: { CommonLazyLoadImage },
  props: {
    content: { type: Object, default: null, required: true }
  },
  computed: {
    tagTitle () {
      if (this.content.category_text === '婚活' && this.$store.isSP) return '婚活・結婚'
      if (this.content.category_text === '街コン') return '街コン・婚活'
      return this.content.category_text
    },
    showMoreText () {
      if (this.content.category_text) {
        const iveryButtonText = this.content.category_text === '街コン' ? '街コン・婚活' : this.content.category_text
        return this.$store.isSP ? `${iveryButtonText}コラム一覧をみる` : `${iveryButtonText}の記事一覧`
      }
      return 'もっと見る'
    },
    seeMoreBtnWrapperClasses () {
      return this.$store.isSP ? ['marginX3'] : ['alignRight', 'marginR2', 'marginY3']
    }
  },
  methods: {
    formatPostDate (date) {
      if (!date) return ''
      return timeConverter(date).fullDateStandard
    }
  }
}
</script>

<style lang="scss" scoped>
.contentBlock {
  padding-bottom: 1.6rem;
  margin: 0.7rem 1.6rem 3rem;
  border-radius: 3px;
  background: $white;
}

.iveryList {
  padding: 1rem 2rem;
  border-radius: 1.2rem;
  background-color: $white;
  @include pc {
    box-shadow: 0 0 3px $gray-c6;
  }
  &-item {
    &:last-child {
      .iveryItem {
        border-bottom: 0;
      }
    }
  }
  @include sp {
    padding: 0.6rem 1rem 0;
  }
}

.iveryItem {
  @include flex;
  align-items: stretch;
  padding: 1rem 0;
  border-bottom: 1px solid $gray-c6;
  @include sp {
    padding: 1rem 0;
    border-bottom-color: $gray-c6;
  }
  &:hover {
    opacity: 0.75;
  }
  &-img {
    width: 72px;
    margin-right: 1rem;
    img {
      display: block;
    }
  }
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include sp {
      color: $gray-55;
    }
    &-body {
      margin-bottom: auto;
      font-size: 1.2rem;
      color: $gray-55;
    }
    &-date {
      margin-top: auto;
      font-size: 1.3rem;
      line-height: 1;
      text-align: right;
      color: $gray-80;
      @include sp {
        color: $gray-80;
        font-size: 1rem;
      }
    }
  }
}

.tagList {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  &-item {
    display: block;
    padding: 0 0.5rem;
    background: $gray-55;
    border-radius: 3px;
    font-size: 1.1rem;
    font-weight: bold;
    color: $white;
    @include sp {
      background: $gray-f1;
      padding: 0.3rem 0.7rem 0.1rem;
      border-radius: 12px;
      font-size: 1rem;
      color: $gray-55;
      line-height: 1.2rem;
    }
  }
}

.ivery-show-more {
  width: 100%;
  margin: 0 auto;
  @include pc {
    width: 470px;
    padding-top: 1rem;
  }
}
</style>
