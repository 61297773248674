<template>
  <div>
    <ul :class="displayMode" class="breadcrumbList">
      <li v-for="(v, idx) in listBreadcrumbs" :key="idx" class="breadcrumbList-item">
        <custom-nuxt-link v-if="idx !== listBreadcrumbs.length - 1" :to="v.path || $route.path">
          {{ v.label }}
        </custom-nuxt-link>
        <span v-else>{{ v.label }}</span>
      </li>
    </ul>
    <breadcrumbs-jsonld :breadcrumbs-list="listBreadcrumbs"/>
  </div>
</template>

<script>
import Breadcrumbs from '@/utils/BreadCrumbs'
const BreadcrumbsJsonld = defineAsyncComponent(() => import('@/components/jsonld/BreadcrumbsJsonld.vue'))
import { BREADCRUMB } from '@/utils/constants/serverCaching'

export default {
  name: BREADCRUMB.name,
  serverCacheKey () {
    return props => `${JSON.stringify(props.options)}::${props.page}::${Math.floor(Date.now() / BREADCRUMB.time)}`
  },
  components: { BreadcrumbsJsonld },
  props: {
    page: { type: String, default: 'default', required: false },
    options: { type: Object, default: () => ({}), required: false },
    displayMode: { type: String, default: 'header', required: false }
  },
  computed: {
    listBreadcrumbs () {
      const breadcrumbs = new Breadcrumbs(this.$route).generate(this.page, this.options).result
      if (this.$route.params.page && this.$route.params.page > 1) {
        breadcrumbs.push({ label: `${this.$route.params.page}ページ目`, path: this.$route.path })
      }
      return breadcrumbs
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbList {
  &.header {
    @include textEllipsis;
    font-size: 1.2rem;
    @include sp {
      margin: 0;
      padding: 1.3rem 1rem 1.1rem;
      font-size: 1rem;
    }
    @at-root {
      .breadcrumbList-item {
        display: inline-block;
        &:not(:last-child) {
          a {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
          &::after {
            content: '>';
            margin: auto 0.5em;
          }
        }
        &:last-child {
          a {
            color: $base;
          }
        }
      }
    }
  }
  &.footer {
    width: calc(100% - 20px);
    margin: 10px auto 10px;
    padding-right: 5px;
    border-radius: 3px;
    background: $white;
    font-size: 10px;
    white-space: nowrap;
    overflow-x: auto;
    li {
      display: inline-block;
      position: relative;
      line-height: 28px;
      a,
      span {
        position: relative;
        padding: 0 5px 0 15px;
        color: $blue-pale;
        font-size: 10px;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
      }
      &:first-child {
        a,
        span {
          padding-left: 10px;
        }
      }
      &:not(:last-child) {
        background: $white;
        &::before,
        &::after {
          position: absolute;
          top: 50%;
          width: 0;
          height: 0;
          margin-top: -14px;
          border-top: 14px solid transparent;
          border-bottom: 14px solid transparent;
          content: '';
          z-index: 5;
        }
        &::after {
          right: -13px;
          border-left: 10px solid $white;
        }
        &::before {
          right: -9px;
          border-left: 10px solid $gray-c6;
        }
        &:hover,
        &:active {
          background: $gray-c6;
          &::after {
            border-left: 10px solid $gray-c6;
          }
        }
      }
      &:last-child {
        a,
        span {
          font-weight: normal;
          color: $gray-55;
        }
      }
    }
  }
  &.afterContent { /* TODO: 一旦2つのパンくずデザインが存在してしまう。UIを改修するらしいからそのときにはまとめてしまうこと */
    @include sp {
      margin: 0;
      padding: 1.3rem 1rem 1.1rem;
    }
    .breadcrumbList-item {
      a, span {
        font-size: 1.2rem;
        text-decoration: none;
        color: $gray-55;
      }
    }
  }
}
</style>
