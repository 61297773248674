import Cookies from 'mj-cookie'

export default (name, data) => {
  if (process.server) return
  if (!window.opix) return
  try {
    window.opix('event', name, data)
  } catch (e) {
    const errorMessage = `[Opix] error occurs:
      name: ${name}
      _lb_utm: ${Cookies.get('_lb_utm')}
      e: ${e}`
    // TOODO CHECK THIS
    const nuxtApp = useNuxtApp()
    if (nuxtApp.$bugsnag) nuxtApp.$bugsnag.notify(errorMessage)
  }
}
