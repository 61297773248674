<template>
  <div class="ads">
    <div v-for="(ads, index) in listAds" :key="index" :id="`adscontainer${ads}`"></div>
  </div>
</template>
<script>
import { findPrefectureBySlug } from '@/models/prefectures'

export default {
  props: {
    numberAds: { type: Number, default: () => 1, required: false }
  },
  computed: {
    listAds () {
      return Array.from({ length: this.numberAds }, (v, i) => i)
    }
  },
  mounted () {
    this.loadGoogleAdsLibrary()
    this.renderGoogleAds()
  },
  methods: {
    renderGoogleAds () {
      let query = ''
      const slug = this.$route.params.prefecture
      const pref = findPrefectureBySlug(slug)
      if (pref) query = pref.name

      const scriptTag = document.createElement('script')
      const adsBlock = this.listAds.map(ads => (`{ "container": "adscontainer${ads}" }`)).join(', ')
      const textScript = `
        (function(g,o){g[o]=g[o]||function(){(g[o]["q"]=g[o]["q"]||[]).push(arguments)},g[o]["t"]=1*new Date})(window,"_googCsa");
        var pageOptions = {"pubId": "pub-9616389000213823", "styleId": "7171091727", "query": "${query}", "hl" : "ja"};
        _googCsa("ads", pageOptions, [${adsBlock}]);
      `
      const scriptContent = document.createTextNode(textScript)
      scriptTag.appendChild(scriptContent)
      document.head.appendChild(scriptTag)
    },
    loadGoogleAdsLibrary () {
      const widgetScript = document.createElement('script')
      widgetScript.setAttribute('src', 'https://www.google.com/adsense/search/ads.js')
      widgetScript.setAttribute('async', '')
      widgetScript.setAttribute('defer', '')
      document.head.appendChild(widgetScript)
    }
  }
}
</script>

<style lang="scss" scoped>
.ads {
  margin: 10px 0;
  @include sp {
    padding: 15px;
  }
}
</style>
