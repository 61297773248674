import type { DynamicUrlParams } from '@/types'

export const WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY: Record<DynamicUrlParams, number> = {
  'region': 1,
  'prefecture': 2,
  'area[]': 3,
  'content[]': 4,
  'feature': 5,
  'cat[]': 6,
  'exclude-sold-out': 7,
  's': 8,
}

export const WHITELIST_SLUG = [
  '/regions/',
  '/areas/',
  '/ag',
  '/features/',
  '/categories/',
  '/interests/',
  '/prefecture_rankings/',
  '/csp/',
  '/promoters/',
  '/dates/'
]

export const WHITELIST_NOINDEX_SEARCH_URL_PARAMS = Object.keys(WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY) as DynamicUrlParams[]

export const URL_PARAMS_DEFAULT_PRIORITY: number = 1000000
