const MAX_DEPTH = 3
const DEFAULT_COMPONENT = '@/pages/search.vue'
const SEARCH_EVENT_ROUTES = [
  {
    name: 'fixed-events',
    path: '/fixed/:prefecture?'
  },
  {
    name: 'search-page',
    path: '/search/page/:page',
    without_paging: true
  },
  {
    name: 'areas-events',
    path: '/areas'
  },
  {
    name: 'custom-search-events',
    path: '/csp/:custom_search'
  },
  {
    name: 'areas-custom-search-events',
    path: '/areas/:prefecture/csp/:custom_search'
  },
  {
    name: 'regions-custom-search-events',
    path: '/regions/:region/csp/:custom_search'
  },
  {
    name: 'areas-area-custom-search-events',
    path: '/areas/:prefecture/:area_group/:area/csp/:custom_search'
  },
  {
    name: 'similar-events',
    path: '/similar_events/:event_code'
  },
  {
    name: 'similar-events-week',
    path: '/similar_events/:event_code/week'
  },
  {
    name: 'interests-activity',
    path: '/interests/activity/:activity_theme?',
    depth: 1
  },
  {
    name: 'categories-interests',
    path: '/categories/:category/interests/:interest?/:sub_interest?',
    depth: 1
  },
  {
    name: 'interests',
    path: '/interests/:interest?/:sub_interest?',
    depth: 1
  },
  {
    name: 'categories',
    path: '/categories/:category',
    depth: 1
  },
  {
    name: 'features-events',
    path: '/features/:feature_tag',
    depth: 1
  },
  {
    name: 'promoters',
    path: '/promoters/:promoter',
    depth: 1
  },
  {
    name: 'areas-afs',
    path: '/areas/:prefecture/demo/afs_archive_sp'
  },
  {
    name: 'areas',
    path: '/areas/:prefecture/:area_group?/:area?',
    has_intl: true,
    depth: 2
  },
  {
    name: 'dates-thisweekend',
    path: '/dates/thisweekend',
    without_paging: true
  },
  {
    name: 'dates-nextweekend',
    path: '/dates/nextweekend',
    without_paging: true
  },
  {
    name: 'regions',
    path: '/regions/:region',
    depth: 2
  },
  {
    name: 'dates',
    path: '/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?',
    depth: 3
  }
]

const appendPaginationAndIntlRoutes = (routes) => {
  const res = []
  // Because Vue match route in the order defined in the router.
  // MUST define paging route (path/page/:page) before the target route
  // If not, it cannot match the route that contains optional params
  // (EX: /area/tokyo/page/2 -> matched with /areas/:prefecture/:area_group/:area with prefecture: tokyo, area_group: page, area: 2)
  routes.forEach(route => {
    if (!route.without_paging) res.push({ name: `${route.name}-page`, path: `${route.path}/page/:page` })
    if (route.has_intl) res.push({ name: `${route.name}-intl`, path: `${route.path}/intl/:lang` })
    res.push(route)
  })
  return res
}

// Build list of the parent-child route with format /parent_path/child_path/child_path/....
const buildParentChildRoutes = (parentRoute, childRoutes) => {
  return childRoutes.map(childRoute => {
    return {
      name: `${parentRoute.name}-${childRoute.name}`,
      path: parentRoute.path + childRoute.path
    }
  })
}

// Generate the nested routes by depth
const nestedRoutes = () => {
  const routes = []
  for (let i = 0; i < MAX_DEPTH; i++) {
    const currentDepthRoutes = SEARCH_EVENT_ROUTES.filter(v => v.depth === i + 1)
    const childRoutes = [...routes]
    currentDepthRoutes.forEach(currentRoute => {
      if (childRoutes.length > 0) routes.push(...buildParentChildRoutes(currentRoute, childRoutes))
      routes.push(currentRoute)
    })
  }
  return appendPaginationAndIntlRoutes(routes)
}

const ListSearchEventsRoutes = () => {
  // single routes
  const singleRoutes = appendPaginationAndIntlRoutes(SEARCH_EVENT_ROUTES.filter(v => !v.depth))
  return [ ...singleRoutes, ...nestedRoutes() ].map(v => {
    return {
      name: v.name,
      path: v.path,
      file: DEFAULT_COMPONENT
    }
  })
}

const ListSearchEventsRoutesSet = () => {
  const routePath = []
  SEARCH_EVENT_ROUTES.map((route) => {
    // first word of path is '/'
    routePath.push(route.path.split('/')[1])
  })
  return [...new Set(routePath)]
}

const SearchRoute = {
  ListSearchEventsRoutes: ListSearchEventsRoutes(),
  ListSearchEventsRoutesSet: ListSearchEventsRoutesSet()
}

export default SearchRoute
