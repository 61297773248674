<template>
  <div class="paddingX3 marginB3">
    <div :class="{ grayBorder: displayBorderBg }" class="lineFriend">
      <div class="lineFriend-description"><span class="textBold">LINE公式アカウント</span><br>定期的にお得な情報配信中！</div>
      <a href="https://lin.ee/BSP3yfR" class="lineFriend-button" @click="buttonClicked">
        <common-lazy-load-image :image-object="{ width: 116, height: 36 }" :is-fixed-dimension="true" alt="友だち追加" image-url="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" background-color="none" />
      </a>
    </div>
  </div>
</template>

<script>
import GTM from '@/utils/tracking/gtm'
const CommonLazyLoadImage = defineAsyncComponent(() => import('@/components/CommonLazyLoadImage.vue'))
import { MJ_LINE_BUTTON_CLICK } from '@/utils/constants/gaCustomEventSet'
export default {
  components: { CommonLazyLoadImage },
  props: {
    displayBorderBg: { type: Boolean, default: true, required: false }
  },
  methods: {
    buttonClicked () {
      GTM.sendEventToGa({ ...MJ_LINE_BUTTON_CLICK, label: 'LINE友だち追加ボタン押下' })
    }
  }
}
</script>

<style lang="scss" scoped>
.lineFriend {
  padding: 1.5rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 3px $gray-c6;
  color: $black-1a;
  background: $white;
  display: flex;
  &.grayBorder {
    border: 1px solid $gray-c6;
  }
  &-description {
    height: fit-content;
    font-size: 1.2rem;
    max-width: 60%;
    margin: auto 0;
  }
  &-button {
    display: inline-flex;
    margin: auto;
  }
}
</style>
