export const flattenArray = (arr) => {
  return [].concat.apply([], arr)
}

export const uniqArray = (arr) => {
  return [...new Set(arr)]
}

export const splitArray = (array, len) => {
  return [...Array(Math.ceil(array.length / len)).keys()].map(i => array.slice(i * len, (i + 1) * len))
}
