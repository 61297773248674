<template>
  <NuxtLayout>
    <not-found-page v-if="error.statusCode === 404" :page-data="{ ads }" />
    <div v-else class="container">
      <div class="errorNotification alignCenter">
        <div class="errorNotification-title textBold margin3">一時的なエラーが発生しました</div>
        <div class="errorNotification-content">
          大変申し訳ございません。一時的なエラーが発生しました。しばらく時間をおいても改善されない場合、お手数ですが<a href="https://support.machicon.jp">サポート</a>までお問い合わせください。
        </div>
        <div class="errorNotification-footer alignCenter margin3">
          <custom-nuxt-link to="/" class="commonButton errorNotification-footer-button btn-destructive-50">
            トップページへ
          </custom-nuxt-link>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script>
const NotFoundPage = defineAsyncComponent(() => import('@/components/NotFoundPage.vue'))
export default {
  components: { NotFoundPage },
  props: {
    error: { type: Object, default: () => ({}), required: true }
  },
  computed: {
    ads () {
      return this.$store.$state.layout_data.ads || {}
    }
  },
  created () {
    this.error.statusCode === 404 ? setPageLayout('default') : setPageLayout('simple')
    const errorMessage = `[${this.error.statusCode}] ${this.error.url}\n${this.error.message}\n${this.error.stack.replace(/<[^>]*>/g, '')}`
    console.error(errorMessage)
    if (this.$bugsnag && this.error.statusCode !== 404) this.$bugsnag.notify(errorMessage)
  }
}
</script>

<style lang="scss" scoped>
.errorNotification {
  margin: 0 auto;
  padding: 1rem;
  background: $pink-light;
  width: 100%;
  height: auto;
  @include pc {
    border: 1px solid $gray-c6;
    border-radius: 5px;
    width: 500px;
  }
  &-title {
    color: $pink-thin;
  }
  &-content {
    color: $black-1a;
  }
  &-footer {
    &-button {
      margin: 0 auto;
      width: 50%;
    }
  }
}
</style>
