<template>
  <div v-if="notifications.length > 0" class="notificationMessage">
    <div class="notificationMessage-inner">
      <ul class="notificationMessage-list">
        <li v-for="(notification, index) in notifications" :key="index" class="notificationMessage-list-item messageListItem">
          <custom-nuxt-link v-if="notification.link" :key="`l${index}`" :to="notification.link" class="messageListItem-title">
            {{ notification.title }}
          </custom-nuxt-link>
          <p v-else :key="`p${index}`" class="messageListItem-title">{{ notification.title }}</p>
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerInformations: { type: Array, default: () => ([]), required: true }
  },
  computed: {
    notifications () {
      const informations = this.headerInformations || []
      return informations.map(v => {
        const link = v.url || `/information/${v.id}/`
        return { title: v.title, link }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notificationMessage {
  position: relative;
  background-color: $white;
  &-inner {
    padding: 1.6rem 0;
    @include pc {
      padding: 1.6rem 0 0.2rem;
    }
    margin: auto;
    max-width: $pcContentWidth;
    font-size: 1.3rem;
    font-weight: bold;
    color: $orange-dark;
    text-decoration: underline;
  }
}

.messageListItem {
  margin: 0.25rem 1rem;
  &-title {
    color: $orange-dark;
  }
}
</style>
