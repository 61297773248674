<template>
  <div>
    <div class="container single spHidden">
      <breadcrumbs page="error404" />
    </div>
    <search-page :search-result="{ ads, events: [], totalCount: 0 }" :hide-bread-crumb="true" />
  </div>
</template>

<script>
import AdvertisementData from '@/utils/AdvertisementData'
const Breadcrumbs = defineAsyncComponent(() => import('@/components/Breadcrumbs.vue'))
import SearchPage from '@/pages/search'
import { useMainStore } from '@/store'
export default {
  components: { Breadcrumbs, SearchPage },
  props: {
    pageData: { type: Object, default: () => ({}), required: false }
  },
  setup () {
    const store = useMainStore()
    const params = { featured: true, recommendations: true, tie_ups: true }
    store.SET_STATE({ key: 'error_code', value: 404 })
    setResponseStatus(useRequestEvent(), 404)
    return new AdvertisementData(store, params).fetchData().then(data => data).catch(e => {
      return { ads: {} }
    })
  },
  created () {
    if (this.pageData && this.pageData.ads) this.ads = this.pageData.ads
  }
}
</script>
