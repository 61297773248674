export const WHITELIST_NOINDEX_SEARCH_KEYWORD_URLS = [
'/search/?s=バイク',
'/search/?s=ミュージアム',
'/search/?s=はしご酒',
'/search/?s=2人参加限定',
'/categories/party/?s=社会人',
'/search/?s=保育士',
'/search/?s=年収800万以上',
'/search/?s=新北陸街コン合同会社',
'/search/?s=2人1組',
'/search/?s=障害者+婚活パーティー',
'/?s=MOST',
'/?s=既婚者',
'/?s=自衛隊',
'/?s=ボードゲーム',
'/?s=1対1',
'/?s=年収1000万',
'/?s=名古屋',
'/?s=脱出ゲーム',
'/?s=お散歩',
'/?s=トヨタ関連',
'/?s=年上女性',
'/?s=50代',
'/?s=ランニング',
'/?s=水族館',
'/?s=高尾山',
'/?s=高収入',
'/?s=ゴルフ',
'/?s=ボウリング',
'/?s=平日休み',
'/?s=麻雀',
'/?s=バドミントン',
'/?s=高身長　女性',
'/?s=個室',
'/?s=高学歴',
'/?s=苫小牧',
'/?s=友活',
'/?s=Rooters',
'/?s=陶芸',
'/?s=女子大生',
'/?s=大手企業',
'/?s=ラヴィ',
'/?s=港区',
'/?s=年収600万',
'/?s=ダーツ',
'/?s=韓国',
'/?s=年下彼氏',
'/?s=博物館',
'/?s=今日',
'/?s=MARIRU',
'/?s=ドライブ',
'/?s=札幌',
'/?s=プレイワークス',
'/?s=ビアガーデン',
'/?s=カフェ',
'/?s=アクアリウム',
'/?s=国家公務員',
'/?s=新宿',
'/?s=神社',
'/?s=ぽっちゃ',
'/?s=占い',
'/?s=食べ歩き',
'/search/?s=ゲーム好き',
'/?s=大人',
'/?s=郡山',
'/?s=上場企業',
'/?s=エリート',
'/?s=恵比寿',
'/search/?s=水上バス',
'/?s=ビール',
'/?s=ペア',
'/search/?s=卓球',
'/?s=アイムシングル',
'/?s=飲み放題',
'/?s=大宮',
'/?s=つくば',
'/?s=消防士',
'/?s=スイーツ',
'/categories/party/?s=リンクバル',
'/?s=平成生まれ',
'/?s=経営',
'/?s=弁護士',
'/?s=地方出身',
'/?s=士業',
'/?s=年収500万',
'/?s=Conversion',
'/?s=楽しい',
'/?s=熊谷',
'/?s=カードゲーム',
'/?s=2人',
'/?s=海',
'/?s=体育会',
'/?s=高身長',
'/?s=ランチ',
'/?s=四日市',
'/?s=GOKUフェス',
'/?s=サンシャイン水族館',
'/?s=学生限定',
'/?s=薬剤師',
'/?s=同世代',
'/?s=クラフトビール',
'/search/?s=着席',
'/?s=カレー',
'/search/?s=年下男性',
'/?s=中央区',
'/?s=オンラインシェアハウス',
'/search/?s=身長175cm以上',
'/?s=年収400万',
'/?s=連絡先交換自由',
'/?s=社会人',
'/search/?s=ハートカフェ',
'/?s=お台場',
'/?s=カジュアルパーティー',
'/?s=職業',
'/?s=年上男性',
'/?s=漫画',
'/?s=CIA-Systems',
'/?s=大卒',
'/?s=バッティング',
'/categories/konkatsu/?s=真剣交際希望の同世代婚活パーティー',
'/?s=茜会',
'/categories/konkatsu/?s=福岡　個室',
'/?s=ビュッフェ',
'/?s=カジュアル',
'/?s=スーツ',
'/?s=女性限定',
'/?s=土日休み',
'/?s=寺',
'/?s=ノンスモーカー',
'/?s=80年',
'/?s=デート',
'/?s=年上彼氏',
'/?s=たこ焼き',
'/?s=安定男子',
'/?s=テーマパーク',
'/?s=オシャレ',
'/categories/konkatsu/?s=1年以内に結婚したい方限定',
'/search/?s=等々力渓谷',
'/?s=アート',
'/categories/konkatsu/?s=名古屋　個室',
'/?s=プランニング',
'/?s=アニコン',
'/?s=パン屋',
'/?s=PARTY PARTY',
'/?s=夏',
'/?s=食べ放題',
'/?s=アクアパーク',
'/?s=大人の婚活パーティー',
'/?s=スポーツ',
'/?s=パン',
'/?s=パーティ パーティ',
'/?s=1dayCoupLink',
'/?s=男女比',
'/?s=170cm',
'/search/?s=パン屋',
'/?s=肉',
'/?s=大手企業&page=3',
'/?s=散歩',
'/?s=井の頭動物園',
'/search/?s=アニコン',
'/?s=バツ'
]