import xss from 'xss'

export default {
  methods: {
    useI18 (key, params = {}, defaultValue = '') {
      if (!this.$te(key)) return defaultValue
      return this.$t(key, params)
    },
    sanitizedXssHtml (content) {
      // white list HTML tags: https://github.com/leizongmin/js-xss/blob/master/lib/default.js#L11
      return xss(content)
    }
  }
}
