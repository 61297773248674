export const INTEREST_CATEGORIES = ['game', 'outdoor', 'learn', 'animal', 'artamuse']
export const DESCRIPTION_FOR_INTEREST = {
  bus: '婚活バスツアー（お見合いバスツアー）出会い・イベント一覧！テレビでも取り上げられ一躍有名となった婚活バスツアー。バスツアーを通じてじっくり結婚相手を探したい方におススメです！',
  sports: 'スポーツ好きのための出会い・イベント一覧！人気スポーツを通して男女問わず親しくなれるイベントが沢山あります！スポーツ好きの友達・恋人を作りたい人におススメです！',
  futsal: 'フットサル好きのための出会い・イベント一覧！皆でフットサルをしながら協力して仲良くなれます！初心者大歓迎！フットサルをしながら充実した時間を過ごしたい人おススメです！',
  bouldering: 'ボルダリング好きのための出会い・イベント一覧！最近流行りのボルダリングを皆で楽しめます！もちろん初心者も大歓迎！ボルダリング体験をしつつ友達・恋人を探したいという人におススメです！',
  tennis: 'テニス好きのための出会い・イベント一覧！経験者・初心者大歓迎！楽しく運動しながら友達・恋人を作りたい人におススメです！',
  cooking: 'アウトドア好きのための出会い・イベント一覧！休日は外で自然に触れたりお出かけしたいという方必見！同じアウトドア好きの友達・恋人を探したい方にもおススメです！',
  anime: 'アニメ好きのための出会い・イベント一覧！色々なジャンルのアニメ好きが集まるのでワイワイ話します！ヲタ友を探している方やアニメ好きカップルに憧れる方におススメです！',
  nazotoki: '謎解き好きのための出会い・イベント一覧！一緒に謎解きをする中でたくさん会話が弾みます！頭を使うことや謎解きが好きな方にはおススメです！',
  travel: '旅・旅行好きのための出会い・イベント一覧！旅好き同士、名スポットや旅のトラブルなどをアツく語れます！旅好きや旅に興味のある方におススメです！',
  survivalgame: 'サバゲ―好きのための出会い・イベント一覧！サバゲ―ならゲームのドキドキがそのままつり橋効果でになってくれるかも！ゲームも恋愛もドキドキしたい人におススメです！',
  fishing: '釣り好きのための出会い・イベント一覧！釣り好き同士、一緒の話題があるからすぐに打ち解けられます！釣り好きや釣りに興味のある方におススメです！',
  jinro: '人狼好きのための出会い・イベント一覧！皆で人狼しながら気軽に仲良くなれます！スタッフが教えてくれるので初心者も大歓迎！気軽に楽しく人狼をしてみたい人におススメです！',
  soccer: 'サッカー好きのための出会い・イベント一覧！好きなチームが同じ人やサッカー観戦仲間が見つかります！サッカー好きで友達・恋人を探したいと思っている方におススメです！',
  baseball: '野球好きのための出会い・イベント一覧！好きな球団が同じ人や試合の観戦仲間が見つかります！野球好きで友達・恋人を探したいと思っている方におススメです！',
  game_other: 'ゲーム/アニメ好きのための出会い・イベント（その他）一覧！共通の話題・アクティビティがあるからすぐに打ち解けられます！ゲーム好きの友達・恋人を作りたい人におススメです！',
  outdoor_other: 'アウトドア好きのための出会い・イベント（その他）一覧！休日は外で自然に触れたりお出かけしたいという方必見！同じアウトドア好きの友達・恋人を探したい方にもおススメです！',
  music: '音楽好きのための出会い・イベント一覧！邦ロック・バンド・フェス好きなどが集まるので仲良くなれること間違いナシ！音楽好きやフェス好きの人にはおススメです！',
  karaoke: '野菜・果物狩り出会い・イベント一覧！旬の野菜・果物が好きなだけ味わえます！野菜・果物好きは勿論、食の趣味が合う友達・恋人を探している方にもおススメです！',
  art: '美術館出会い・イベント一覧！展覧会を見ながら充実した時間を過ごせます！美術館巡りが好きな人、美術・アートが好きな人におススメです！',
  commedy: 'お笑いコン出会い・イベント一覧！漫才を見たりお笑い芸人・ライブの話で盛り上がれます！お笑い芸人が好きな友達・恋人を探している方にもおススメです！',
  train: '鉄道好きのための出会い・イベント一覧！鉄道に乗ったり鉄道トークが充実しています！鉄男・鉄女の方はもちろん、鉄道に興味があるなーという人でも楽しめるイベントです☆',
  books: '読書コン出会い・イベント一覧！本屋やブックカフェで充実した読書トークができます！読書好きの友人・恋人を探したい方におススメです！',
  camera: 'カメラコン出会い・イベント一覧！カメラ・写真好き同士でお気に入りの写真やカメラについて話せるイベントです！カメラ・写真が好きな友人・恋人を探したい方にもおススメです！',
  history: '歴史好きのための出会い・イベント一覧！寺散策・座禅・陶芸といった伝統に触れるアクティビティが充実！ちょっとマイナーだからこそ親密になれるかも？歴史好きの友人・恋人を探したい方におススメです！',
  cat: '猫好きのための出会い・イベント一覧！猫カフェでまったり癒されながら会話が弾みます！同じアウトドア好きの友達・恋人を探したい方にもおススメです！',
  tour: '観光体験・ガイドツアー体験出会い・イベント一覧！充実した観光体験をしながら参加者でお話できます！観光やガイドツアーを通じて素敵な友人・恋人に出会いたい方におススメです！',
  sea: '海の体験出会い・イベント一覧！綺麗な青い海で童心に帰った気分で遊べます！海や夏が好きな人、海で充実した時間を過ごしたい方におススメです！',
  river: '川・湖の体験出会い・イベント一覧！川遊びやBBQといった充実したイベントが揃っています！川やアウトドア好きな人におススメです！',
  mount: '山の体験出会い・イベント一覧！登山・トレッキングで汗を流しつつ自然を満喫できます！登山に興味のある人、登山好き、山好きの友達・恋人を探したい時間を過ごしたい方におススメです！',
  sky: '空の体験出会い・イベント一覧！スリルあるアクティビティを通じてワクワクすること間違いナシ！！空のアクティビティに興味のある友達・恋人を探したい時間を過ごしたい方におススメです！',
  snow: '雪の体験出会い・イベント一覧！寒い冬だからこそ全力で楽しめるイベントが勢揃い！雪や冬が好きな人、雪遊びで充実した時間を過ごしたい方におススメです！',
  vegetables: '野菜・果物狩り出会い・イベント一覧！旬の野菜・果物が好きなだけ味わえます！野菜・果物好きは勿論、食の趣味が合う友達・恋人を探している方にもおススメです！',
  culture: '日本文化コンのための出会い・イベント一覧！寺散策・座禅・陶芸といった伝統に触れるアクティビティが充実！日本文化好きの友人・恋人を探したい方におススメです！',
  vehicle: '乗り物好きのための出会い・イベント一覧！ナイトクルーズや水上バスといった乗り物アクティビティが充実！非日常感のある体験が好きな人におススメです！',
  ceramics: 'ものづくりコン出会い・イベント一覧！陶芸・ハーバリウム作り・食品サンプル作りといった体験が出来るイベントです！ものづくり・ハンドメイドに興味がある人におススメです！',
  job: '職業体験コン出会い・イベント一覧！バーテンダーや農業といった職業をちょっと体験できるイベント！農作業やカクテル作りといった職業体験に興味がある人におススメです！',
  game: 'ゲーム・アニメ好きのための出会い・イベント一覧！サバゲ―・謎解き・アニメ・人狼など趣味が合うから自然と会話が弾む！好きなものが一緒の友達・恋人を作りたい人におススメです！',
  outdoor: 'アウトドア好きのための出会い・イベント一覧！自然に触れるアクティビティや観光イベントを通じて話が弾むこと間違いナシ！アウトドア好きの友達・恋人を作りたい人におススメです！',
  artamuse: 'アミューズメント好きのための出会い・イベント一覧！カラオケ・お笑い・映画といったエンターテイメント好きな人が集まるイベントです！アミューズメント好きの友達・恋人を探したい方にもおススメです！',
  learn: '学びコン・イベント一覧！学ぶことや教養を深めることが好きな人が集まるイベントです！知的な友達・恋人を探したい方にもおススメです！',
  animal: '動物好きのための出会い・イベント一覧！猫や犬、動物園といった動物好きが集まるイベントです！動物好き同士で交流を深めたいという人におススメです！',
  'interest-other': '趣味コン出会い・イベント一覧！共通の趣味で初対面なのに話が弾みやすい！自分の趣味に合った友達・恋人に出会いたい人におススメです！',
  learn_other: '学ぶことが好きな人のための出会い・イベント（その他）一覧！何か学びたい！学びを楽しみたいという方必見！学ぶことが好きな友達・恋人を探したい方にもおススメです！',
  animal_other: '動物好きのための出会い・イベント（その他）一覧！動物好き（猫・犬・その他様々！）と気軽に話せるイベント多数！動物好き同士で交流を深めたいという人におススメです！',
  walking: '夜景観賞・街歩き・お出かけができる出会い・イベント一覧！綺麗な景色や充実した体験をしながら参加者同士で仲良くなれます！休日に楽しく散策・お出かけしたいと思っている方おススメです',
  drink: 'お酒コン出会い・イベント一覧！美味しいお酒を片手に和やかにトークできます！とにかくお酒を楽しみたい人や呑み友・恋人を探したい方におススメです！',
  zoo: '動物園コン・出会いイベント一覧！動物園を散策しながら動物好きの参加者と楽しくお話できるイベントが勢揃い！動物好きの友達・恋人を探したい方にもおススメです！',
  dog: '犬好きのための出会い・イベント一覧！参加者全員が犬好きなので愛犬や好きな犬種の話で盛り上がれます！犬好きの友達・恋人を探したい方にもおススメです！',
  museum: '美術館出会い・イベント一覧！展覧会を見ながら充実した時間を過ごせます！美術館巡りが好きな人、美術・アートが好きな人におススメです！',
  comedy: 'お笑いコン出会い・イベント一覧！漫才を見たりお笑い芸人・ライブの話で盛り上がれます！お笑い芸人が好きな友達・恋人を探している方にもおススメです！',
  craft: 'ものづくりコン出会い・イベント一覧！陶芸・ハーバリウム作り・食品サンプル作りといった体験が出来るイベントです！ものづくり・ハンドメイドに興味がある人におススメです！',
  'sports-other': 'スポーツ・アウトドア好きのための出会い・イベント（その他）一覧！休日は外で充実したアクティビティをしたいという方必見！自分と同じスポーツ・アウトドア好きの友達・恋人を探したい方にもおススメです！',
  artamuse_other: 'アート/アミューズ好きのための出会い・イベント（その他）一覧！をアート/アミューズ思いっきり楽しみたい！お出かけしたいという方必見！同じアート/アミューズ好きの友達・恋人を探したい方にもおススメです！',
  movie: '映画好きのための出会い・イベント一覧！映画鑑賞や映画トークで打ち解けられるイベントです（邦画・洋画・アニメ映画の何でもOK）映画友達や映画の趣味が合う恋人を探している方にもおススメです！'
}
export const INTEREST_REDIRECT_LINK = {
  'survivalgame': '/interests/game/survivalgame',
  'nazotoki': '/interests/game/nazotoki',
  'anime': '/interests/game/anime',
  'jinro': '/interests/game/jinro',
  'sports': '/interests/sports/sports-other',
  'fishing': '/interests/outdoor/fishing',
  'travel': '/interests/outdoor/travel',
  'bus': '/interests/outdoor/bus',
  'tour': '/interests/outdoor/tour',
  'sea': '/interests/outdoor/sea',
  'river': '/interests/outdoor/river',
  'mount': '/interests/outdoor/mount',
  'sky': '/interests/outdoor/sky',
  'snow': '/interests/outdoor/snow',
  'vegetables': '/interests/outdoor/vegetables',
  'music': '/interests/artamuse/music',
  'karaoke': '/interests/artamuse/karaoke',
  'art': '/interests/artamuse/museum',
  'commedy': '/interests/artamuse/comedy',
  'culture': '/interests/artamuse/culture',
  'vehicle': '/interests/artamuse/vehicle',
  'train': '/interests/artamuse/train',
  'amuse': '/interests/artamuse/other',
  'food': '/interests/learn/cooking',
  'ceramics': '/interests/learn/craft',
  'job': '/interests/learn/job',
  'cooking': '/interests/learn/cooking',
  'books': '/interests/learn/books',
  'camera': '/interests/learn/camera',
  'history': '/interests/learn/history',
  'cat': '/interests/animal/cat',
  'drink': '/interests/interest-other/drink'
}
