import sendOpixEvent from '@/utils/sendOpixEvent'
import { OPIX_ONLINE_EVENT_TABS } from '@/utils/constants/opixTracking'

export default {
  data () {
    return {
      scrolledPosition: 0
    }
  },
  computed: {
    currentEventTypeTab () {
      return OPIX_ONLINE_EVENT_TABS[this.$store.$state.search_page.on_online_event_tab] || ''
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const current = window.innerHeight + window.pageYOffset
      if (this.scrolledPosition > current) return
      if (!this.baseCheckPoints) return
      this.scrolledPosition = current
      this.baseCheckPoints.forEach(checkPoint => {
        const checkPointRef = document.querySelector(`.${checkPoint.class}`)
        if (checkPointRef && current > checkPointRef.offsetTop) {
          this.sendOpixScrolledDatas(checkPoint.itemName)
          const index = this.baseCheckPoints.indexOf(checkPoint)
          if (index > -1) this.baseCheckPoints.splice(index, 1)
        }
      })
    },
    sendOpixScrolledDatas (showedBlock) {
      const opixDatas = {
        userID: this.isLoggedIn ? this.$store.$state.my_profile.id : null,
        currentRoute: import.meta.env.ROOT_URL + this.$route.fullPath,
        lastRoute: this.$store.$state.previous_page_path ? import.meta.env.ROOT_URL + this.$store.$state.previous_page_path : this.$store.$state.previous_page_path,
        showedBlock: showedBlock,
        recommendMethod: this.checkRecommendVersion(showedBlock)
      }
      sendOpixEvent('user_scroll_track', opixDatas)
    },
    sendOpixClickDatas (data) {
      const opixDatas = {
        userID: this.isLoggedIn ? this.$store.$state.my_profile.id : null,
        currentRoute: import.meta.env.ROOT_URL + data.path,
        lastRoute: import.meta.env.ROOT_URL + this.$route.fullPath,
        showedBlock: data.showedBlock,
        recommendMethod: null
      }
      sendOpixEvent('user_click_track', opixDatas)
    },
    sendMJCLFooterDatas (action, clickTab = null) {
      const rootURL = import.meta.env.ROOT_URL
      const fullPath = import.meta.env.ROOT_URL + this.$route.fullPath
      const previousPath = this.$store.$state.previous_page_path ? rootURL + this.$store.$state.previous_page_path : null
      const isSearchPage = this.$store.$state.is_search_page
      const opixDatas = {
        userID: (this.$store.$state.my_profile && this.$store.$state.my_profile.id) || null,
        currentRoute: action === 'show' ? fullPath : null,
        lastRoute: action === 'show' ? previousPath : fullPath,
        action: action,
        clickTab: clickTab,
        listingTab: isSearchPage ? this.currentEventTypeTab : null
      }
      sendOpixEvent('link_to_other_products', opixDatas)
    },
    sendOpixPurchaseData (paymentKey) {
      const rootURL = import.meta.env.ROOT_URL
      const opixDatas = {
        userID: (this.$store.$state.my_profile && this.$store.$state.my_profile.id) || null,
        currentRoute: rootURL + this.$route.fullPath,
        lastRoute: this.$store.$state.previous_page_path ? rootURL + this.$store.$state.previous_page_path : null,
        paymentKey: paymentKey
      }
      sendOpixEvent('start_purchase', opixDatas)
    },
    checkRecommendVersion (showedBlock) {
      if (showedBlock === 'AIRecommend_related_search_results_new') return this.recommendVersionEventListNew
      if (showedBlock === 'AIRecommend_related_recent_check_new') return this.recommendVersionRecentCheckedNew
      if (showedBlock === 'AIRecommend_personalized_new') return this.recommendVersionPersonalizedNew
      if (showedBlock === 'AIRecommend_related_bookmarks_new') return this.recommendVersionEventBookmarkNew
      if (showedBlock === 'AIRecommend_new') return this.recommendVersionNew
      if (showedBlock === 'AIRecommend_alternative_date') return this.alternativeDateRecommendVersion
      return null
    },
    sendOpixSearchByKeyword (keyword) {
      const opixDatas = {
        userID: (this.$store.$state.my_profile && this.$store.$state.my_profile.id) || null,
        currentRoute: import.meta.env.ROOT_URL + `/?s=${keyword}`,
        lastRoute: import.meta.env.ROOT_URL + this.$route.fullPath,
        free_word: keyword
      }
      sendOpixEvent('free_word_search', opixDatas)
    }
  }
}
