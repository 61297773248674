import { numberToCurrency, timeConverter } from '@/utils/converter'
import { formatMonthDate } from '@/utils/formatter'
import EventModel from '@/models/events'
const DEFAULT_LIST_VALUE = { name: '指定なし', value: 0 }
const DISPLAY_CONTENT_TYPES = {
  1: ['one_store', 'multiple_stores'],
  2: ['sit_down', 'stand_up', 'both', 'other_format'],
  3: ['yes_foods', 'no_foods'],
  4: ['yes_drinks', 'no_drinks'],
  5: ['many_people', 'konkatsu', 'have_interest', 'for_beginner', 'single']
}
const ORGANIZERS = [
  { name: '街コンジャパン主催', value: 'linkbal' }
]
// to map form data with query params
const FORM_DATA_AND_QUERY_MAPPING = {
  prefectures: ['prefecture', 'prefecture[]', 'path_params[area_city_prefecture_slug_eq]', 'prefecture_slug'],
  region: ['region', 'path_params[area_city_prefecture_region_slug_eq]'],
  areas: ['area[]', 'path_params[area_slug_in][]', 'area'],
  week_days: 'wday[]',
  num_of_people: 'num-of-people',
  num_of_people_range: 'num-of-people-range[]',
  user_age: 'user-age',
  companion_age: 'companion-age',
  price_to: 'to-price',
  price_from: 'from-price',
  content_types: ['content[]', 'path_params[content_type_in][]'],
  sale_types: 'sales[]',
  exclude_sold_out: 'exclude-sold-out',
  organizers: 'organizer[]',
  user_gender: 'gender',
  keyword: 's',
  features: ['feature', 'path_params[feature_tags_slug_eq]'],
  purposes: 'cat_in[]',
  syumiplusPurposes: 'feature[]',
  interestPurposes: 'interest[]',
  fixed: 'fixed',
  has_stage_discount_tickets: 'has_stage_discount_tickets',
  has_mj_select: 'has_mj_select',
  from_time: 'from-time',
  start_dates: 'start_dates[]',
  onlineEventTypes: 'online_event_type[]',
  address_venue: 'address_venue[]',
}
// the list data that has datatype are array
const ARRAY_FORM_DATA = ['prefectures', 'areas', 'week_days', 'content_types', 'sale_types', 'organizers', 'features', 'purposes', 'interestPurposes', 'syumiplusPurposes', 'start_dates', 'onlineEventTypes', 'address_venue', 'num_of_people_range']
// age limitation
const MIN_AGE = 18
const MAX_AGE = 60

export default class SearchForm {
  constructor (queryParams) {
    this.queryParams = queryParams
  }

  get listNumberOfPeoples () {
    const list = [DEFAULT_LIST_VALUE]
    for (let i = 0; i < 4; i++) list.push({ name: `${i + 1}人`, value: i + 1 })
    return list
  }

  get userAgeRanges () {
    const ranges = [DEFAULT_LIST_VALUE]
    for (let i = MIN_AGE; i < MAX_AGE; i++) ranges.push({ name: i, value: i })
    return ranges
  }

  get priceRange () {
    const range = [DEFAULT_LIST_VALUE]
    for (let i = 1; i <= 10; i++) {
      const value = i * 1000
      range.push({ name: numberToCurrency(value), value: value })
    }
    return range
  }

  get listSaleTypes () {
    const saleTypes = EventModel.listSaleTypesForSearch()
    return Object.keys(saleTypes).map(v => ({ value: v, name: saleTypes[v] }))
  }

  get listOrganizers () {
    return ORGANIZERS
  }

  listContentTypesByGroup (group) {
    const contentTypes = DISPLAY_CONTENT_TYPES[group]
    if (!contentTypes) return []
    return contentTypes.map(v => ({ value: v, name: EventModel.getContentTypeValue(v) }))
  }

  // Convert query params to search form data
  get mapQueryToFormData () {
    const formData = []
    Object.keys(FORM_DATA_AND_QUERY_MAPPING).forEach(dataItem => {
      const mapQueries = FORM_DATA_AND_QUERY_MAPPING[dataItem]
      const queryKey = (mapQueries instanceof Array) ? mapQueries.find(v => this.queryParams[v]) : mapQueries
      if (this.queryParams[queryKey]) {
        formData[dataItem] = ARRAY_FORM_DATA.includes(dataItem) && !(this.queryParams[queryKey] instanceof Array) ? [this.queryParams[queryKey]] : this.queryParams[queryKey]
      }
    })

    // mapping date params
    if (this.queryParams['from-year'] && this.queryParams['from-month'] && this.queryParams['from-day']) {
      formData['start_date'] = `${this.queryParams['from-year']}-${formatMonthDate(this.queryParams['from-month'])}-${formatMonthDate(this.queryParams['from-day'])}`
    }
    if (this.queryParams['to-year'] && this.queryParams['to-month'] && this.queryParams['to-day']) {
      formData['end_date'] = `${this.queryParams['to-year']}-${formatMonthDate(this.queryParams['to-month'])}-${formatMonthDate(this.queryParams['to-day'])}`
    }
    if (this.queryParams['path_params[start_on_gteq][]']) {
      formData['start_date'] = this.queryParams['path_params[start_on_gteq][]'].join('-')
    }
    if (this.queryParams['path_params[start_on_lteq][]']) {
      formData['end_date'] = this.queryParams['path_params[start_on_lteq][]'].join('-')
    }

    if (this.queryParams['regions']) {
      formData['region'] = (this.queryParams['regions'] instanceof Array) ? this.queryParams['regions'].join('_') : this.queryParams['regions']
    }

    return formData
  }

  // Convert search form data to query params
  convertFormDataToQueryParams (data) {
    const queryParams = {}
    Object.keys(data).forEach(key => {
      if (!data[key] || ((data[key] instanceof Array) && data[key].length === 0)) return
      const mapQuery = (FORM_DATA_AND_QUERY_MAPPING[key] instanceof Array) ? FORM_DATA_AND_QUERY_MAPPING[key][0] : FORM_DATA_AND_QUERY_MAPPING[key]
      if (!mapQuery) return
      if (mapQuery === 'region' && data[key].includes('_')) { // if select multiple regions by the combined slug
        queryParams['regions'] = data[key].split('_')
      } else {
        const query = (mapQuery === 'prefecture' && data.region) ? 'prefecture[]' : mapQuery
        queryParams[query] = data[key]
      }
    })

    // mapping date
    const isEmptyStartDates = !data.start_dates || ((data.start_dates instanceof Array) && data.start_dates.length === 0)
    if (isEmptyStartDates && !(data.start_date instanceof Array)) {
      if (data.start_date) {
        const startDate = timeConverter(data.start_date)
        queryParams['from-year'] = startDate.year
        queryParams['from-month'] = startDate.month
        queryParams['from-day'] = startDate.day
      }
      if (data.end_date) {
        const endDate = timeConverter(data.end_date)
        queryParams['to-year'] = endDate.year
        queryParams['to-month'] = endDate.month
        queryParams['to-day'] = endDate.day
      }
    }
    return queryParams
  }
}
