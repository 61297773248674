<template>
  <div class="paddingX3 marginB4">
    <div :class="{ grayBorder: displayBorderBg }" class="clMobileApps">
      <div class="clMobileApps-description marginB3"><span class="textBold">公式アプリ</span><br>全国各地の街コン・パーティーを簡単に探せます！</div>
      <ul class="clMobileApps-appList">
        <li class="clMobileApps-appList-item marginR3">
          <a href="/app-download?sp_banner=app_download">
            <common-lazy-load-image :image-url="appstoreBadge" :image-object="{ width: 135, height: 40 }" :is-fixed-dimension="true" alt="Storeからダウンロード" background-color="none" />
          </a>
        </li>
        <li class="clMobileApps-appList-item">
          <a href="/app-download?sp_banner=app_download">
            <common-lazy-load-image :image-url="googleplayBadge" :image-object="{ width: 135, height: 40 }" :is-fixed-dimension="true" alt="Playで手に入れよう" background-color="none" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { CACHE_COUPLINK_MOBILE_APP } from '@/utils/constants/serverCaching'
const CommonLazyLoadImage = defineAsyncComponent(() => import('@/components/CommonLazyLoadImage.vue'))
import appstoreBadge from '/assets/images/svg/appstore_badge.svg'
import googleplayBadge from '/assets/images/svg/googleplay_badge.png'
export default {
  name: CACHE_COUPLINK_MOBILE_APP.name,
  components: { CommonLazyLoadImage },
  serverCacheKey () {
    return Math.floor(Date.now() / CACHE_COUPLINK_MOBILE_APP.time)
  },
  props: {
    displayBorderBg: { type: Boolean, default: true, required: false }
  },
  data () {
    return {
      appstoreBadge,
      googleplayBadge
    }
  }
}
</script>

<style lang="scss" scoped>
.clMobileApps {
  padding: 1.5rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 3px $gray-c6;
  color: $black-1a;
  background: $white;
  &.grayBorder {
    border: 1px solid $gray-c6;
  }
  &-description {
    min-height: 5rem;
    font-size: 1.2rem;
  }
  &-appList {
    @include flex;
    &-item {
      width: 100%;
      max-width: 135px;
      height: auto;
    }
  }
}
</style>
