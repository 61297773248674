export const REGIONS = [
  {
    'id': 1,
    'name': '北海道',
    'slug': 'hokkaido'
  },
  {
    'id': 2,
    'name': '東北',
    'slug': 'tohoku'
  },
  {
    'id': 3,
    'name': '関東',
    'slug': 'kanto'
  },
  {
    'id': 4,
    'name': '北陸・甲信越',
    'slug': 'hokuriku'
  },
  {
    'id': 5,
    'name': '東海',
    'slug': 'tokai'
  },
  {
    'id': 6,
    'name': '関西',
    'slug': 'kinki'
  },
  {
    'id': 7,
    'name': '中国',
    'slug': 'chugoku'
  },
  {
    'id': 8,
    'name': '四国',
    'slug': 'shikoku'
  },
  {
    'id': 9,
    'name': '九州・沖縄',
    'slug': 'kyushu'
  },
  {
    'id': 10,
    'name': 'その他',
    'slug': 'other'
  }
]
