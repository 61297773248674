const AUTOMATIC_FILTER_PARAMS = ['filter[exclude_sold_out]', 'use_cache', 'page']
const LOCATION_FILTERS = ['filter[area]', 'filter[area_group]', 'filter[prefecture]', 'filter[region]', 'filter[regions]']
const PARTICIPATION_FILTERS = ['user_gender', 'user_age', 'filter[participation_condition][num_of_people]', 'filter[participation_condition][companion_age]']
// min number of query param when search event for no index.
// min number of search values for param when search event for no index.
const MIN_QUERY_PARAMS_VALUE_FOR_NO_INDEX = 2

export default apiSearchParams => {
  AUTOMATIC_FILTER_PARAMS.forEach(param => delete apiSearchParams[param])
  LOCATION_FILTERS.reduce((isExist, param) => {
    if (isExist) {
      delete apiSearchParams[param]
      return isExist
    }
    // assign isExist to true if has value
    if (apiSearchParams[param]) return true
  }, false)

  // Add noindex if more than two condition on 参加条件で絞り込む is selected.
  const participartionFiltersExist = PARTICIPATION_FILTERS.filter(item => apiSearchParams[item])
  if (participartionFiltersExist.length >= MIN_QUERY_PARAMS_VALUE_FOR_NO_INDEX) return true
  
  const apiSearchParamsKeys = Object.keys(apiSearchParams)
  const hasExceedLimitParams = apiSearchParamsKeys.some(key => {
    return typeof apiSearchParams[key] === 'string' && apiSearchParams[key].split(',').length >= MIN_QUERY_PARAMS_VALUE_FOR_NO_INDEX
  })

  return hasExceedLimitParams
}
