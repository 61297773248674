import moment from 'mj-moment'
import UserModel from '@/models/users'
import { findRegionByPrefectureSlug } from '@/models/places'
import { compactObject } from '@/utils/object'
export default {
  isSP: state => state.sp,
  isPC: state => state.pc,
  isLoggedIn: state => state.auth_info !== null && !!state.auth_info['access-token'],
  myId: state => state.my_profile ? state.my_profile.id : '',
  myGender: state => state.my_profile ? state.my_profile.gender : '',
  isFemaleUser: state => state.my_profile && state.my_profile.gender === 'female',
  isMaleUser: state => state.my_profile && state.my_profile.gender === 'male',
  notHaveTelNumber: state => state.my_profile && !state.my_profile.tel,
  myAge: state => {
    if (!state.my_profile || !state.my_profile.birthday) return null
    return moment().diff(state.my_profile.birthday, 'year')
  },
  myName: state => state.my_profile ? state.my_profile.name : '',
  myPersonalSearchParams: (state) => {
    if (!state.my_profile) return {}
    const params = {
      region: state.my_profile.prefecture_slug ? findRegionByPrefectureSlug(state.my_profile.prefecture_slug).slug : '',
      gender: state.myGender ? UserModel.getGenderValue(state.myGender) : '',
      'user-age': state.myAge
    }
    return compactObject(params)
  },
  bookmarkEventsCount: state => state.bookmark_events ? state.bookmark_events.length : 0,
  ssrCookie: state => {
    if (!state.ssr_cookie) return {}
    return state.ssr_cookie.split('; ').reduce((result, keyVal) => {
      const [key, val] = keyVal.split('=').map(v => decodeURIComponent(v))
      try {
        return Object.assign(result, { [key]: JSON.parse(val) })
      } catch (e) {
        return Object.assign(result, { [key]: val })
      }
    }, {})
  }
}
