import { formatMonthDate } from '@/utils/formatter'
import { buildQueryString } from '@/utils/url-action'
import { flattenArray, uniqArray } from '@/utils/array'
import moment from 'mj-moment'
import EventModel from '@/models/events'
import UserModel from '@/models/users'
import GlobalCookie from '@/utils/GlobalCookie'
import PageDetector from '@/utils/PageDetector'
import { INTEREST_CATEGORIES } from '@/utils/constants/interests'

// mapping path/query params with api search conditions (filter conditions)
const MAP_FILTER_SEARCH_CONDITIONS = {
  regions: 'regions',
  region: 'region',
  prefecture: 'prefecture',
  prefecture_slug: 'prefecture',
  area_city_prefecture_slug_eq: 'prefecture',
  area_city_prefecture_area_group_slug_eq: 'area_group',
  area_group: 'area_group',
  area: 'area',
  feature_tag: 'feature_tag',
  feature: 'feature_tag',
  interest: 'interest',
  sub_interest: 'interest',
  category: 'event_type',
  categories: 'event_type',
  custom_search: 'custom_search',
  promoter: 'promoter',
  latitude: 'latitude',
  longitude: 'longitude',
  place: 'place',
  activity_theme: 'activity_theme',
  activity_theme_slug: 'activity_theme',
  activity_genre_slug: 'activity_genre',
  // 使われていないです
  // style: 'style',
  fixed: 'fixed',
  has_stage_discount_tickets: 'has_stage_discount_tickets',
  has_mj_select: 'has_mj_select',
  s: 'keyword',
  start_or_end_at_gte: ['start_at_or_end_at', 'gte'],
  start_or_end_at_lt: ['start_at_or_end_at', 'lt'],
  start_at_gte: ['start_at', 'gte'],
  start_at_lte: ['start_at', 'lte'],
  'prefecture[]': 'prefecture',
  'area[]': 'area',
  'interest[]': 'interest',
  'feature[]': 'feature_tag',
  'num-of-people': ['participation_condition', 'num_of_people'],
  'num-of-people-range[]': 'num_of_people_range',
  'companion-age': ['participation_condition', 'companion_age'],
  'weekdays': ['start_at', 'weekday_in'],
  'to-price': ['price', 'lte'],
  'from-price': ['price', 'gte'],
  'exclude-sold-out': 'exclude_sold_out',
  'content[]': 'content_type',
  'sales[]': 'sales_type',
  'have-interest': 'have_interest',
  'organizer[]': 'promoter',
  'from-time': 'from_time',
  'start_dates[]': 'start_dates',
  'online_event_type[]': 'online_event_type',
  'address_venue[]': 'address_venue',
}

// mapping path/query params with api search conditions
const MAP_NORMAL_SEARCH_CONDITIONS = {
  gender: 'user_gender',
  only_count: 'only_count',
  page: 'page',
  'user-age': 'user_age',
  area_count: 'area_count'
}

const SIDE_CONTENTS_PARAMS_KEYS_SP = ['event_type', 'have_interest']

const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export default class SearchParams {
  constructor (store, route, options = {}) {
    this.params = options['searchFormParams'] || { ...route.params, ...route.query }
    this.store = store
    this.route = route
    this.options = options
    this.pageDetector = new PageDetector(this.route)

    this.convertToSearchParams()
    this.addSpecialParamsByPath()
  }

  // convert to search api param's format
  convertToSearchParams () {
    this.convertStartDateParams()
      .convertDateParams()
    this.convertCategories()
    this.convertPurposesToCategories()
    this.convertWeekDays()
    this.convertGender()
  }

  // convert start_date
  convertStartDateParams () {
    if (this.params.start_date) {
      const startDate = moment(this.params.start_date)
      this.params.day = formatMonthDate(startDate.date())
      this.params.month = formatMonthDate(startDate.month() + 1)
      this.params.year = startDate.year()
    }
    return this
  }

  convertDateParams () {
    if (this.params.day) {
      const month = formatMonthDate(this.params.month)
      const day = formatMonthDate(this.params.day)
      this.params.start_or_end_at_gte = moment(`${this.params.year}-${month}-${day}`).format('YYYY-MM-DD')
      this.params.start_or_end_at_lt = moment(this.params.start_or_end_at_gte).add(1, 'days').format('YYYY-MM-DD')
    } else if (this.params.month) {
      const month = formatMonthDate(this.params.month)
      this.params.start_or_end_at_gte = moment(`${this.params.year}-${month}-01`).format('YYYY-MM-DD')
      this.params.start_or_end_at_lt = moment(this.params.start_or_end_at_gte).add(1, 'months').format('YYYY-MM-DD') // next month
    } else if (this.params.year) {
      this.params.start_or_end_at_gte = `${this.params.year}-01-01`
      this.params.start_or_end_at_lt = moment(this.params.start_or_end_at_gte).add(1, 'years').format('YYYY-MM-DD') // next year
    } else {
      if (this.params['from-day'] && this.params['from-month'] && this.params['from-year']) {
        const month = formatMonthDate(this.params['from-month'])
        const day = formatMonthDate(this.params['from-day'])
        this.params.start_at_gte = moment(`${this.params['from-year']}-${month}-${day}`).format('YYYY-MM-DD')
      }
      if (this.params['to-day'] && this.params['to-month'] && this.params['to-year']) {
        const month = formatMonthDate(this.params['to-month'])
        const day = formatMonthDate(this.params['to-day'])
        this.params.start_at_lte = moment(`${this.params['to-year']}-${month}-${day}`).format('YYYY-MM-DD')
      }
    }
  }

  convertCategories () {
    if (!this.params['cat[]']) return
    if (this.params['cat[]'] instanceof Array) {
      this.params.categories = this.params['cat[]'].map(v => EventModel.getEventTypeByValue(v))
    } else {
      this.params.categories = EventModel.getEventTypeByValue(this.params['cat[]'])
    }
  }

  convertPurposesToCategories () {
    if (!this.params['cat_in[]']) return
    const categories = this.params.categories ? flattenArray([this.params.categories]) : []
    const rawPurposes = (this.params['cat_in[]'] instanceof Array) ? this.params['cat_in[]'] : [this.params['cat_in[]']]
    const purposeCats = flattenArray(rawPurposes.map(v => EventModel.getEventTypesByPurpose(v) || v))

    this.params.categories = uniqArray(categories.concat(purposeCats))
  }

  convertWeekDays () {
    const weekDay = this.params['wday[]']
    if (!weekDay) return
    if ((weekDay instanceof Array) && weekDay.length > 0) {
      const wDays = [...weekDay]
      this.params.weekdays = wDays.sort().map(v => WEEKDAYS[Number(v) - 1])
    } else {
      this.params.weekdays = WEEKDAYS[Number(weekDay) - 1]
    }
  }

  convertGender () {
    if (!this.params.gender) return
    this.params.gender = UserModel.findGenderByValue(this.params.gender)
  }

  addSpecialParamsByPath () {
    if (!this.route) return
    if (this.route.name === 'fixed-events' || this.route.name === 'fixed-events-page') this.params.fixed = 1
    if (this.route.name && this.route.name.includes('interests')) this.params['have-interest'] = 1
    if (/interests\/activity\/($|page\/)/.test(this.route.path)) {
      this.params['category'] = this.params['category'] ? `${this.params['category']},activity` : 'activity'
    }
  }

  searchQueryParamKey (param) {
    const filterConditionParams = Object.keys(MAP_FILTER_SEARCH_CONDITIONS)
    const normalConditionParams = Object.keys(MAP_NORMAL_SEARCH_CONDITIONS)
    if (normalConditionParams.includes(param)) return MAP_NORMAL_SEARCH_CONDITIONS[param]
    if (filterConditionParams.includes(param)) {
      const filterParam = MAP_FILTER_SEARCH_CONDITIONS[param]
      if (typeof filterParam === 'string') return `filter[${filterParam}]`
      return `filter${filterParam.map(v => `[${v}]`).join('')}`
    }
    return ''
  }

  // add default conditions by user profile or cookie
  addDefaultConditionsByUser (conditions) {
    const isCountApi = conditions.only_count || conditions.area_count
    if (isCountApi) {
      conditions = this.addOnSaleCondition(conditions, conditions.user_gender)
      return conditions
    }

    const globalCookie = new GlobalCookie(this.store)
    const gender = conditions.user_gender || this.store.myGender || globalCookie.get('gender')
    const age = conditions.user_age || this.store.myAge || globalCookie.get('age')
    const prefecture = globalCookie.get('prefecture')

    conditions = this.addOnSaleCondition(conditions, gender)

    // add age, gender by default if not choosen
    if (!conditions.user_gender && gender) conditions.user_gender = gender
    if (!conditions.user_age && age) conditions.user_age = age

    if (this.needAppendPrefectureCondition(conditions, prefecture)) conditions['filter[prefecture]'] = prefecture
    return conditions
  }

  addOnSaleCondition (conditions, gender) {
    if (!gender) return conditions
    const onSaleCondition = `on_sale_${gender}_without_ticket_on_sale_es_filter`
    if (conditions['filter[sales_type]']) {
      conditions['filter[sales_type]'] = `${conditions['filter[sales_type]']},${onSaleCondition}`
    } else {
      conditions['filter[sales_type]'] = onSaleCondition
    }
    return conditions
  }

  isFromGrowthPushUrl () {
    return this.params['utm_source'] === 'app' && this.params['utm_medium'] === 'push' && this.params['utm_campaign']
  }

  needAppendPrefectureCondition (conditions, prefecture) {
    return prefecture && !this.pageDetector.isAreasTopPage && !this.pageDetector.isSearchEventsFromTopPage &&
      !conditions['filter[prefecture]'] && !conditions['filter[region]'] && !conditions['filter[regions]'] && !this.pageDetector.isSearchEventsPage
  }

  // convert params to api search conditions
  get apiSearchParams () {
    const conditions = {}
    Object.keys(this.params).forEach(paramKey => {
      const apiParamKey = this.searchQueryParamKey(paramKey)
      if (!apiParamKey || !this.params[paramKey]) return
      conditions[apiParamKey] = (this.params[paramKey] instanceof Array) ? this.params[paramKey].join(',') : this.params[paramKey]
    })
    // 配信PUSHの際に負荷の対策
    if (this.isFromGrowthPushUrl()) conditions['use_cache'] = 1
    if (this.pageDetector.isFeatureDetailPage) conditions['feature_detail'] = 1
    if (/\/interests\/\w+\/other\//.test(this.route.path)) {
      const interestCategory = this.route.params.interest
      if (INTEREST_CATEGORIES.includes(interestCategory)) {
        conditions['interest_category'] = interestCategory
      }
    }
    return this.addDefaultConditionsByUser(conditions)
  }

  get searchApiQueryString () {
    return buildQueryString(this.apiSearchParams)
  }

  get searchSideContentQueryString () {
    const params = { is_sp: true }
    SIDE_CONTENTS_PARAMS_KEYS_SP.forEach(key => {
      if (!this.apiSearchParams[`filter[${key}]`]) return
      params[`filter[${key}]`] = this.apiSearchParams[`filter[${key}]`]
    })

    if (this.route.query['gender']) {
      params['user_gender'] = UserModel.findGenderByValue(this.route.query['gender'])
    }

    if (this.route.query['user-age']) {
      params['user_age'] = this.route.query['user-age']
    }

    return buildQueryString(params)
  }

  get getPurposesByParams () {
    const purposes = []
    const currentParams = { ...this.params }
    const catIn = currentParams['cat_in[]']

    if (catIn) {
      const catIns = (catIn instanceof Array) ? catIn : [catIn]
      purposes.push(...catIns)
    }

    if (currentParams['cat[]']) {
      const eventType = EventModel.getEventTypeByValue(currentParams['cat[]'])
      if (EventModel.getPurposeByEventType(eventType)) {
        const purpose = EventModel.getPurposeByEventType(eventType)
        purposes.push(purpose)
      }
    }

    if (currentParams.category) {
      const purpose = EventModel.getPurposeByEventType(currentParams.category)
      if (purpose) purposes.push(purpose)
    }

    if (this.pageDetector.isInterestEventsPage) {
      const purpose = EventModel.getPurposeByEventType('interest')
      purposes.push(purpose)
    }

    return uniqArray(purposes)
  }
}
