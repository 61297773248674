import Cookies from 'mj-cookie'
import moment from 'mj-moment'
import EventModel from '@/models/events'
import UserModel from '@/models/users'
import SearchForm from '@/utils/search/SearchForm'
import SearchParams from '@/utils/search/SearchParams'
import GlobalCookie from '@/utils/GlobalCookie'
import sendOpixEvent from '@/utils/sendOpixEvent'
import { buildQueryString } from '@/utils/url-action'

const DEFAULT_MODEL_DATA = {
  prefectures: [],
  region: null,
  areas: [],
  start_date: null,
  start_dates: [],
  end_date: null,
  week_days: [],
  user_gender: null,
  num_of_people: null,
  num_of_people_range: [],
  user_age: null,
  companion_age: null,
  purposes: [],
  content_types: [],
  sale_types: [],
  price_from: 0,
  price_to: 0,
  exclude_sold_out: 1,
  organizers: [],
  keyword: null,
  features: [],
  fixed: null,
  has_stage_discount_tickets: null,
  has_mj_select: null,
  from_time: '',
  syumiplusPurposes: [],
  interestPurposes: [],
  onlineEventTypes: [],
  address_venue: [],
}

export default {
  data () {
    return {
      totalCount: 0,
      fetchedCountQuery: null,
      model: {
        ...DEFAULT_MODEL_DATA
      }
    }
  },
  computed: {
    listPurposes () {
      return EventModel.getListPurposes()
    },
    searchForm () {
      return new SearchForm(this.$route.query)
    },
    searchQueryParams () {
      return this.searchForm.convertFormDataToQueryParams(this.model)
    },
    searchApiQueryString () {
      const searchFormParams = { ...this.searchQueryParams, only_count: 1 }
      const searchParams = new SearchParams(this.$store, this.$route, { searchFormParams })
      return searchParams.searchApiQueryString
    },
    // TODO 6078 refactoring this function
    // purposes や interestPurposes が Object なので、
    // それぞれの key に対して、slug を取得して配列に変換する
    fetchCountQueryParams () {
      const copyModel = { ...this.model }
      copyModel.interestPurposes = copyModel.interestPurposes.map(purpose => purpose)
      copyModel.purposes = copyModel.purposes.filter(purpose => purpose !== 'interest').map(purpose => purpose)
      return this.searchForm.convertFormDataToQueryParams(copyModel)
    },
    fetchCountApiQueryString () {
      const searchFormParams = { ...this.fetchCountQueryParams, only_count: 1 }
      const searchParams = new SearchParams(this.$store, this.$route, { searchFormParams })
      return searchParams.searchApiQueryString
    },
    canUseKonkatsuFreePass () {
      const globalCookie = new GlobalCookie(this.$store)
      const userGender = this.$store.myGender || globalCookie.get('gender')
      return userGender === 'female'
    },
    userDefaultGender () {
      return this.$store.myGender || Cookies.get('gender')
    },
    userDefaultAge () {
      return this.$store.myAge || Cookies.get('age')
    },
    isCheckedConditions () {
      return (
        (!this.$store.isLoggedIn && (this.model.user_gender !== null || this.model.user_age !== null)) ||
        this.model.num_of_people !== null ||
        this.model.num_of_people_range?.length ||
        this.model.companion_age !== null ||
        this.model.price_from !== 0 ||
        this.model.price_to !== 0 ||
        this.model.keyword !== null ||
        this.model.fixed ||
        this.model.has_stage_discount_tickets ||
        this.model.has_mj_select ||
        this.model.onlineEventTypes.length ||
        this.model.content_types?.length ||
        this.model.address_venue?.length
      )
    }
  },
  watch: {
    // this watching will be called when router or model changing
    searchApiQueryString () {
      this.fetchCount()
    }
  },
  created () {
    this.initializeData()
  },
  mounted () {
    this.fetchCount()
  },
  methods: {
    getWdayName (wday) {
      const listOfWeekdays = moment.weekdaysShort()
      return listOfWeekdays[wday - 1]
    },

    /**
    * Init search condition data.
    * We ignore initializeOnlineEventTypes if user submit spSearch form with search_form_version query param
    */
    initializeData () {
      this.buildConditionData()
      if (!this.$route.query.search_form_version) this.initializeOnlineEventTypes()
      if (typeof this.afterInitializeData === 'function') this.afterInitializeData()
    },

    /**
    * Build search condition model data from query params
    */
    buildConditionData () {
      const formData = this.searchForm.mapQueryToFormData
      Object.keys(formData).forEach(key => {
        this.model[key] = formData[key]
      })
      if (this.userDefaultGender) this.model.user_gender = UserModel.getGenderValue(this.userDefaultGender)
      if (this.userDefaultAge) this.model.user_age = this.userDefaultAge
    },
    /**
    * At EventListHeader, we always update on_online_event_tab state when router changed
    * So we init OnlineEventTypes condition base on on_online_event_tab state
    */
    initializeOnlineEventTypes () {
      const currentTab = this.$store.$state.search_page.on_online_event_tab
      this.model.onlineEventTypes = !currentTab || (currentTab === 'all') ? ['online', 'offline'] : [currentTab]
    },
    fetchCount () {
      // TODO 6078の refactoring あと、元に戻すこと
      // const queryString = this.searchApiQueryString
      const queryString = this.fetchCountApiQueryString
      if (this.fetchedCountQuery === queryString) return
      this.fetchedCountQuery = queryString
      this.$store.apiGet(`events/${queryString}`).then(res => {
        this.totalCount = res.data.count
      }).catch(e => {
        this.fetchedCountQuery = null
      })
    },
    clearConditions () {
      Object.keys(DEFAULT_MODEL_DATA).forEach(key => {
        this.model[key] = DEFAULT_MODEL_DATA[key]
      })
      if (typeof this.afterClearConditions === 'function') this.afterClearConditions()
    },
    setFormDataToCookie () {
      if (this.model.user_age) {
        Cookies.set('age', this.model.user_age)
      } else {
        Cookies.remove('age')
      }
      if (this.model.user_gender) {
        Cookies.set('gender', UserModel.findGenderByValue(this.model.user_gender))
      } else {
        Cookies.remove('gender')
      }
    },
    toSearchResultPage () {
      if (!this.$store.isLoggedIn) this.setFormDataToCookie()
      if (Object.keys(this.searchQueryParams).length === 0) this.searchQueryParams['exclude-sold-out'] = 1
      this.model.purposes = this.model.purposes.filter(purpose => purpose !== 'interest')
      // Add and update search_form_version to identify SpSearch submitting request and force fetching data again
      const searchFormVersion = this.$route.query.search_form_version ? parseInt(this.$route.query.search_form_version) + 1 : 1
      if (this.searchQueryParams.s) {
        this.$store.dispatch('createOrUpdateKeywordSuggestion', { keyword: this.searchQueryParams.s })
      }
      this.$router.push({ name: 'search', query: { ...this.searchQueryParams, search_form_version: searchFormVersion } })
      if (this.model.keyword) {
        const opixDatas = {
          userID: (this.$store.$state.my_profile && this.$store.$state.my_profile.id) || null,
          currentRoute: import.meta.env.ROOT_URL + `/search/${buildQueryString(this.searchQueryParams)}`,
          lastRoute: import.meta.env.ROOT_URL + this.$route.fullPath,
          free_word: this.model.keyword
        }
        sendOpixEvent('free_word_search', opixDatas)
      }
      if (typeof this.afterRedirectToResultPage === 'function') this.afterRedirectToResultPage()
    },
    listAreasHasEventsByPrefecture (listAreasByPrefecture, eventCountInArea) {
      return listAreasByPrefecture.filter((area) => eventCountInArea(area.id) > 0)
    }
  }
}
