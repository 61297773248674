import { REGIONS } from '@/config/data/regions'
import { PREFECTURES } from '@/config/data/prefectures'
import { AREA_GROUPS } from '@/config/data/area_groups'
import { AREAS } from '@/config/data/areas'
export const listRegionsPrefectures = () => {
  const list = []
  REGIONS.forEach(region => {
    list.push({ ...region, type: 'region', prefectures: null })
  })
  PREFECTURES.forEach(prefecture => {
    list.push({ ...prefecture, type: 'prefecture', area_groups: null })
  })
  return list
}

export const getListPrefecturesByRegionId = (id) => {
  const region = REGIONS.find(v => v.id === id)
  if (!region) return []
  return PREFECTURES.filter(v => v.region_slug === region.slug)
}

export const findRegionByPrefectureSlug = (slug) => {
  const prefecture = PREFECTURES.find(v => v.slug === slug)
  return REGIONS.find(region => region.slug === prefecture.region_slug)
}

export const findAreaGroupByAreaSlug = (slug) => {
  const area = AREAS.find(v => v.slug === slug)
  if (!area) return null
  return AREA_GROUPS.find(areaGroup => areaGroup.slug === area.area_group_slug)
}

export const findPrefectureByAreaGroupSlug = (slug) => {
  const areaGroup = AREA_GROUPS.find(v => v.slug === slug)
  return PREFECTURES.find(prefecture => prefecture.slug === areaGroup.prefecture_slug)
}

export const findPrefectureByAreaSlug = (slug) => {
  return findPrefectureByAreaGroupSlug(findAreaGroupByAreaSlug(slug).slug)
}

export const isPlaceName = (name) => {
  if (REGIONS.some(region => region.name === name)) return true
  if (PREFECTURES.some(pref => pref.name === name)) return true
  if (AREA_GROUPS.some(pref => pref.name === name)) return true
  if (AREAS.some(pref => pref.name === name)) return true
  return false
}

export const listRegions = () => {
  return REGIONS
}

export const findRegionBySlug = (slug) => {
  return REGIONS.find(v => v.slug === slug)
}

export const listPrefectures = () => {
  return PREFECTURES
}

export const findPrefectureBySlug = (slug) => {
  return PREFECTURES.find(v => v.slug === slug)
}

export const getListPrefectureByRegionSlug = (slug) => {
  return PREFECTURES.filter(v => v.region_slug === slug)
}

export const listAreaGroups = () => {
  return AREA_GROUPS
}

export const findAreaGroupBySlug = (slug) => {
  return AREA_GROUPS.find(v => v.slug === slug)
}

export const getListAreaGroupByPrefectureSlug = (slug) => {
  return AREA_GROUPS.filter(v => v.prefecture_slug === slug)
}

export const listAreas = () => {
  return AREAS
}

export const getListAreasByPrefectureSlug = (slug) => {
  return AREAS.filter(v => v.prefecture_slug === slug)
}

export const getListAreasByAreaGroup = (slug) => {
  return AREAS.filter(v => v.area_group_slug === slug)
}

export const findAreaBySlug = (slug) => {
  return AREAS.find(v => v.slug === slug)
}
