import { formatMonthDate } from '@/utils/formatter'
import moment from 'mj-moment'
import EventModel from '@/models/events'
import { getListAreasByAreaGroup } from '@/models/areas'
import PageDetector from '@/utils/PageDetector'
import GlobalCookie from '@/utils/GlobalCookie'
const DEFAULT_TO_ROUTE = { name: 'search', params: {}, query: {} }
export default class SearchLink {
  constructor (store, currentRoute, route = DEFAULT_TO_ROUTE) {
    this.store = store
    this.currentRoute = currentRoute
    this.route = route // target route
    this.day = null
    this.currentParams = { ...currentRoute.params, ...currentRoute.query }
    this.pageDetector = new PageDetector(this.currentRoute)
  }

  get userPrefectureSlug () {
    return this.store.$state.my_profile ? this.store.$state.my_profile.prefecture_slug : null
  }

  get userPersonalParams () {
    if (!this.store.isLoggedIn) return {}
    return this.store.myPersonalSearchParams
  }

  get toRoute () {
    return this.route
  }

  // 今週末いけるイベントのクエリ
  get weekendEventListQuery () {
    const saturday = moment().endOf('week')
    const sunday = moment(saturday).add(1, 'days')
    return {
      'from-year': saturday.year(),
      'from-month': saturday.month() + 1,
      'from-day': saturday.date(),
      'to-year': sunday.year(),
      'to-month': sunday.month() + 1,
      'to-day': sunday.date()
    }
  }

  get nextWeekendEventListQuery () {
    const nextSaturday = moment().add(7, 'days').endOf('week')
    const nextSunday = moment(nextSaturday).add(1, 'days')
    return {
      'from-year': nextSaturday.year(),
      'from-month': nextSaturday.month() + 1,
      'from-day': nextSaturday.date(),
      'to-year': nextSunday.year(),
      'to-month': nextSunday.month() + 1,
      'to-day': nextSunday.date()
    }
  }

  get dayEventListPath () {
    const params = { year: this.day.year(), month: formatMonthDate(this.day.month() + 1), day: formatMonthDate(this.day.date()) }
    this.route = { name: 'dates', params: params }
    return this.defaultQueryParamsForEventListPath().toRoute
  }

  // 今日いけるイベント
  get currentDateEventListPath () {
    const currentDay = moment()
    this.day = currentDay
    return this.dayEventListPath
  }

  // 明日いけるイベント
  get nextDateEventListPath () {
    const nextDate = moment().add(1, 'days')
    this.day = nextDate
    return this.dayEventListPath
  }
  // link saturday event
  get saturdayEventListPath () {
    const currentDate = moment()
    const nextSaturday = currentDate.add(2, 'days').endOf('week')
    this.day = nextSaturday
    return this.dayEventListPath
  }
  // link sunday event
  get sundayEventListPath () {
    const currentDate = moment()
    const nextSaturday = currentDate.add(2, 'days').endOf('week')
    const nextSunday = nextSaturday.add(1, 'days')
    this.day = nextSunday
    return this.dayEventListPath
  }

  // 今週末いけるイベント
  get weekendDateEventListPath () {
    this.route = { name: 'search', query: this.weekendEventListQuery }
    return this.defaultQueryParamsForEventListPath().toRoute
  }

  get nextWeekendDateEventListPath () {
    this.route = { name: 'search', query: this.nextWeekendEventListQuery }
    return this.defaultQueryParamsForEventListPath().toRoute
  }

  // 今日/明日/今週末いけるイベントリストページ用パラメーター
  defaultQueryParamsForEventListPath (day = moment()) {
    const globalCookie = new GlobalCookie(this.store)
    const prefectureForCalendar = day && globalCookie.get('prefecture') ? { prefecture: globalCookie.get('prefecture') } : null
    const queryParams = { ...this.userPersonalParams, ...prefectureForCalendar, ...this.currentParams }
    // remove region if has prefecture
    if (queryParams.region && queryParams.prefecture) {
      delete queryParams.region
    }
    // convert category name to value
    if (queryParams.category) {
      queryParams['cat[]'] = [EventModel.getEventTypeValue(queryParams.category)]
    }
    // append area params
    if (this.currentParams.prefecture && this.currentParams.area_group && this.currentParams.area) {
      queryParams['area[]'] = this.currentParams.area
    } else if (this.currentParams.prefecture && this.currentParams.area_group) {
      queryParams['area[]'] = getListAreasByAreaGroup(this.currentParams.area_group).map(v => v.slug)
    }
    // append interest params
    if (this.currentRoute.name.includes('interests') || this.currentParams['interest[]']) {
      queryParams['have-interest'] = 1
    }
    if (this.currentParams.sub_interest || this.currentParams.interest) {
      if (this.currentParams.sub_interest) queryParams['sub_interest_slug'] = this.currentParams.sub_interest
      if (this.currentParams.interest) queryParams['interest_slug'] = this.currentParams.interest
      queryParams['interest[]'] = [this.currentParams.sub_interest || this.currentParams.interest]
    }
    // append feature_tag params
    if (this.currentParams.feature_tag) {
      queryParams['feature'] = this.currentParams.feature_tag
    }
    // remove unused params
    const unusedParams = [
      'from-year',
      'from-month',
      'from-day',
      'to-year',
      'to-month',
      'to-day',
      'year',
      'month',
      'day',
      'start_date',
      'start_dates[]',
      'area_group',
      'area',
      'category',
      'interest',
      'sub_interest',
      'feature_tag',
      'bdm',
      'page'
    ]
    unusedParams.forEach(key => {
      delete queryParams[key]
    })
    const toRouteQuery = this.route.query || {}
    this.route.query = { ...toRouteQuery, ...queryParams }
    this.day = day
    return this
  }

  // 条件絞込み検索ページ用のクエリーパラメーター
  queryParamsForSearchDetails () {
    // If current page is search page (the page has path like: /search?params=abcxyz), use current query
    // If no, generate params from this route
    const queryParams = (this.currentRoute.name === 'search' && Object.keys(this.currentRoute.query).length > 0) ? { ...this.currentRoute.query } : this.generateParamsFromCurrentConditions()
    // append custom search
    if (['20sonly', '30sonly', 'around30'].includes(this.currentParams.custom_search)) {
      queryParams['content[]'] = this.currentParams.custom_search
    }
    // append query params if is not search page
    const additionParams = this.currentRoute.name !== 'search' ? this.currentRoute.query : {}
    // user personal search params
    const userParams = this.userPersonalParams
    if (Object.keys(userParams).length > 0) {
      if (!queryParams['user-age'] && userParams['user-age']) queryParams['user-age'] = userParams['user-age']
      if (!queryParams['prefecture'] && !queryParams['region'] && !queryParams['path_params[area_city_prefecture_region_slug_eq]'] && !queryParams['path_params[area_city_prefecture_slug_eq]']) {
        queryParams['prefecture'] = this.userPrefectureSlug
      }
    }
    // remove unused params
    ['start_date', 'bdm'].forEach(pr => {
      delete queryParams[pr]
      delete additionParams[pr]
    })

    if (this.currentParams['cat[]']) {
      const eventType = EventModel.getEventTypeByValue(this.currentParams['cat[]'])
      if (EventModel.getPurposeByEventType(eventType)) {
        queryParams['cat_in[]'] = EventModel.getPurposeByEventType(eventType)
      }
    }

    if (parseInt(this.currentParams['have-interest']) === 1) {
      queryParams['cat_in[]'] = EventModel.getPurposeByEventType('interest')
    }

    const toRouteQuery = this.route.query || {}
    this.route.query = { ...toRouteQuery, ...queryParams, ...additionParams }
    return this
  }

  // TODO: こちらは古いロジックによって実装しますが、もしGETパラメーターは全く同じではなくても大丈夫だったら、こちらは改修したほうがいいです
  // 現在の条件を再利用する
  generateParamsFromCurrentConditions () {
    const params = {}
    if (this.currentParams.region) {
      params['path_params[area_city_prefecture_region_slug_eq]'] = this.currentParams.region
    } else if (this.currentParams.prefecture) {
      params['path_params[area_city_prefecture_slug_eq]'] = this.currentParams.prefecture
      if (this.currentParams.area) {
        params['path_params[area_slug_in][]'] = this.currentParams.area
      } else if (this.currentParams.area_group) {
        params['path_params[area_slug_in][]'] = getListAreasByAreaGroup(this.currentParams.area_group).map(v => v.slug)
      }
    }
    // to store date conditions
    if (this.currentParams.year && this.currentParams.month && this.currentParams.day) {
      params['path_params[start_on_gteq][]'] = params['path_params[start_on_lteq][]'] = [this.currentParams.year, this.currentParams.month, this.currentParams.day]
    } else if (this.currentParams.year && this.currentParams.month) {
      params['path_params[start_on_gteq][]'] = [this.currentParams.year, this.currentParams.month, '01']
      const lastDayOfMonth = moment(`${this.currentParams.year}-${this.currentParams.month}-01`).endOf('month')
      params['path_params[start_on_lteq][]'] = [this.currentParams.year, this.currentParams.month, lastDayOfMonth.date()]
    } else if (this.currentParams.year) {
      params['path_params[start_on_gteq][]'] = [this.currentParams.year, '01', '01']
      params['path_params[start_on_lteq][]'] = [this.currentParams.year, '12', '31']
    }
    // to store interest conditions
    if (this.currentParams.category && EventModel.getPurposeByEventType(this.currentParams.category)) {
      params['cat_in[]'] = EventModel.getPurposeByEventType(this.currentParams.category)
    }
    if (this.pageDetector.isInterestEventsPage) {
      params['cat_in[]'] = EventModel.getPurposeByEventType('interest')
    }

    if (this.currentParams.interest) {
      params['have-interest'] = '1'
      // 使われていなさそうです
      // const interestSlug = this.currentParams.sub_interest || this.currentParams.interest
      // params['g'] = [{ m: 'or', interest_slug_in: interestSlug, interest_parent_slug_in: interestSlug }]
    }
    if (this.currentParams.feature_tag) params['path_params[feature_tags_slug_eq]'] = this.currentParams.feature_tag
    if (this.currentParams.custom_search && ['for_beginner', 'single'].includes(this.currentParams.custom_search)) {
      params['path_params[content_type_in][]'] = this.currentParams.custom_search
    }
    return params
  }
}
