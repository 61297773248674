import Cookies from 'mj-cookie'

export default class Affiliate {
  static setAccessInfo (route, store) {
    const YAHOO_REF_EXPIRES_DAYS = 30
    const query = route.query
    const timeStamp = Math.round((new Date()).getTime() / 1000)

    if (typeof query.utm_source !== 'string' || typeof query.utm_medium !== 'string') return

    // Cookieをセットする(チケット価格の15%のアフィリエイト)
    if (query.utm_source && query.utm_medium.match(/(yahoobanner|pairs|alliance|affiliate)/) && !store.ssrCookie['ssr_yahoo_ref']) {
      const expiresTime = new Date(new Date() * 1 + YAHOO_REF_EXPIRES_DAYS * 864e+5)
      Cookies.set('yahoo_ref', `${timeStamp}::${query.utm_source}`, { expires: expiresTime, path: '/' })
      Cookies.set('yahoo_ref_expires_time', expiresTime.toUTCString(), { expires: expiresTime, path: '/' })
      store.dispatch('apiGet', 'supports/generate_cookie_yahoo')
    }

    // Cookieをセットする(集客手数料無料化のアフィリエイト)
    if (query.utm_source && query.utm_medium.match(/promoter/)) {
      Cookies.set('promoter_ref', `${timeStamp}::${query.utm_source}`, { expires: 1, path: '/' })
    }
  }
}
