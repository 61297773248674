<template>
  <img
    v-if="canDisplayWithDimension"
    :data-src="imageSrc"
    :data-original-url="orignalImageSrc"
    :width="imageWidth"
    :height="imageHeight"
    :style="style"
    :alt="alt"
    class="fixedDimension"
    src="/img/placeholder.gif"
    @error="handleNoWebpImage"
    @load="$emit('lazyLoadingCompleted')"
  />
  <img
    v-else
    :data-src="imageSrc"
    :data-original-url="orignalImageSrc"
    :style="style"
    :alt="alt"
    src="/img/placeholder.gif"
    @error="handleNoWebpImage"
    @load="$emit('lazyLoadingCompleted')"
  >
</template>

<script>
import lozad from 'lozad'
import UAParser from 'ua-parser-js'
import semver from 'semver'

export default {
  emits: ['lazyLoadingCompleted'],
  props: {
    imageObject: { type: Object, default: null, required: false },
    imageUrl: { type: String, default: null, required: false },
    alt: { type: String, default: null, required: false },
    backgroundColor: { type: String, default: '#c6c6c6', required: false },
    isFixedDimension: { type: Boolean, default: false, required: false },
    appendStyle: { type: Object, default: () => ({}), required: false }
  },
  data () {
    return {
      observer: null,
      retried: false
    }
  },
  computed: {
    style () {
      return { backgroundColor: this.backgroundColor, ...this.appendStyle }
    },
    imageSrc () {
      if (!this.imageObject && !this.imageUrl) return ''
      if (this.imageUrl) return this.imageUrl
      const spUrl = this.imageObject.sp_url || this.imageObject.thumb_url || this.imageObject.url
      const url = this.$store.isSP ? spUrl : this.imageObject.url
      if (this.checkWebPSupport()) {
        const regex = /^(http(s)?:\/\/.*)\.\w*$/
        return url?.replace(regex, '$1.webp') || ''
      }
      return url
    },
    orignalImageSrc () {
      if (!this.imageObject && !this.imageUrl) return ''
      if (this.imageUrl) return this.imageUrl
      const spUrl = this.imageObject.sp_url || this.imageObject.thumb_url || this.imageObject.url
      return this.$store.isSP ? spUrl : this.imageObject.url
    },
    imageWidth () {
      return this.$store.isSP && this.imageObject.sp_width ? this.imageObject.sp_width : this.imageObject.width
    },
    imageHeight () {
      return this.$store.isSP && this.imageObject.sp_height ? this.imageObject.sp_height : this.imageObject.height
    },
    canDisplayWithDimension () {
      return this.isFixedDimension && this.imageWidth && this.imageHeight
    }
  },
  watch: {
    imageSrc () {
      // force reload lazy image if image src changed
      if (this.$el && this.$el.getAttribute('data-loaded') === 'true') {
        this.$nextTick(() => {
          this.$el.setAttribute('src', '/img/placeholder.gif')
          this.$el.setAttribute('data-loaded', false)
          this.loadImage()
        })
      }
    }
  },
  mounted () {
    this.observer = lozad(this.$el)
    this.loadImage()
  },
  methods: {
    loadImage () {
      this.observer.observe()
    },
    triggerLoad () {
      if (!this.observer) this.observer = lozad(this.$el)
      this.observer.triggerLoad(this.$el)
    },
    checkWebPSupport() {
      if (this.$store.$state.is_webp_support_ua !== null) {
        return this.$store.$state.is_webp_support_ua
      }

      const { name: browserName, version: browserVersion } = new UAParser().getBrowser()
      const isWebpSupportUA = this.isBrowserWebPSupported(browserName, browserVersion) || true
      this.$store.SET_STATE({ key: 'is_webp_support_ua', value: isWebpSupportUA })

      return isWebpSupportUA
    },
    isBrowserWebPSupported(browserName, browserVersion) {
      // Define minimum supported versions for WebP
      const minSupportedVersions = {
        Safari: '14.0.0',
        Chrome: '9.0.0',
        Edge: '18.0.0',
        Firefox: '65.0.0',
        IE: '11.0.0',
        'Mobile Safari': '14.0.0',
        'Android Browser': '4.0.0',
        'Samsung Internet': '4.0.0'
      }

      // Check if the browser supports WebP based on its version
      const minSupportedVersion = minSupportedVersions[browserName]
      if (!minSupportedVersion) {
        // Unknown browser, assume WebP support
        return true
      }

      return semver.gte(semver.coerce(browserVersion), minSupportedVersion)
    },
    handleNoWebpImage (el) {
      const alternativeImageUrl = el.target.getAttribute('data-original-url')
      if (alternativeImageUrl && !this.retried) {
        el.target.src = alternativeImageUrl
        this.retried = true
      }
    }
  }
}
</script>
