import { useMainStore } from '@/store';

const getCommonOptions = () => {
  const mainStore = useMainStore();
  const headers = {}
  const authInfo = mainStore.auth_info
  if (authInfo) {
    headers['access-token'] = authInfo['access-token']
    headers.client = authInfo.client
    headers.uid = authInfo.uid
  }
  return { headers }
}

const handleError = (e) => {
  const mainStore = useMainStore();
  if (e.response && e.response.status === 401) mainStore.SET_STATE({ key: 'auth_info', value: null })
}

// force reload page if [x-app-version] header changes
const needForceReload = (path, response) => {
  const mainStore = useMainStore();

  // The target APIs: Get header informations API, search events API
  const isTarget = path === 'announcements/header_informations/' || /^events\//.test(path)
  if (process.server || !isTarget) return false

  const newAppVersion = response.headers && response.headers[APP_VERSION_HEADER]
  return newAppVersion && mainStore.app_version !== newAppVersion
}

const emptyPromise = () => {
  return new Promise((resolve, reject) => {
    resolve(null)
  })
}

const isDatingEventCacheUseable = () => {
  const mainStore = useMainStore();
  return mainStore.dating_event_data && mainStore.dating_event_data.cached_at + 60 * 1000 > Date.now()
}

export {
  getCommonOptions,
  handleError,
  needForceReload,
  emptyPromise,
  isDatingEventCacheUseable,
}
