import SearchParams from '@/utils/search/SearchParams'
import HeadMeta from '@/utils/head/HeadMeta'
import HeadLink from '@/utils/head/HeadLink'
import { useMainStore } from '@/store'

export const useHeadParams = (context) => {
  const app = useNuxtApp()
  const store = useMainStore()
  const router = useRouter()
  const apiSearchParams = new SearchParams(store, context.$route).apiSearchParams
  context = { ...context, ...app }
  context.$store = store
  context.$router = router
  if (context.isEventListPage && context.$route.path === '/') return {}
  const headMeta = new HeadMeta(context, apiSearchParams)
  const headLink = new HeadLink(context, apiSearchParams)
  const metas = []

  metas.push({ name: 'reverse', content: true })
  if (headMeta.description) metas.push({ name: 'description', content: headMeta.description })
  if (headMeta.keywords) metas.push({ name: 'keywords', content: headMeta.keywords })
  if (headMeta.viewport) metas.push({ name: 'viewport', content: headMeta.viewport })
  if (headMeta.robots) {
    if (!store.isLoggedIn) store.dispatch('postNoIndexPage', { full_path: context.$route.fullPath }).then(res => {})
    metas.push({ name: 'robots', content: headMeta.robots })
  }
  if (headMeta.ogp) {
    metas.push({ name: 'card', property: 'twitter:card', content: headMeta.ogp['twitter:card'] })
    metas.push({ name: 'site', property: 'twitter:site', content: headMeta.ogp['twitter:site'] })
    metas.push({ name: ':title', property: 'twitter:title', content: headMeta.ogp['twitter:title'] })
    metas.push({ name: 'description', property: 'twitter:description', content: headMeta.ogp['twitter:description'] })
    metas.push({ name: 'image', property: 'twitter:image', content: headMeta.ogp['twitter:image'] })
    metas.push({ name: 'type', property: 'og:type', content: headMeta.ogp['og:type'] })
    metas.push({ name: 'title', property: 'og:title', content: headMeta.ogp['og:title'] })
    metas.push({ name: 'url', property: 'og:url', content: headMeta.ogp['og:url'] })
    metas.push({ name: 'image', property: 'og:image', content: headMeta.ogp['og:image'] })
  }

  useHead ({
    htmlAttrs: { lang: app.$i18n.locale },
    title: headMeta.title,
    titleTemplate: headMeta.titleTemplate,
    meta: metas,
    link: headLink.links
  })
}

export const useNoIndex = () => {
  useHead({ meta: {name: 'robots', content: 'noindex,follow' }})
}
