const DEFAULT_SYSTEM_ERROR_MSG = 'システムエラーが発生しました。もう一度試してみてください'

const LIST_QUERY_PARAMS = [
  {
    name: 'sign_in',
    value: 'completion',
    message: 'ログインしました。',
    type: 'success'
  },
  {
    name: 'sign_in',
    value: 'error',
    message: 'ログイン失敗しました。',
    type: 'error'
  },
  // {
  //   name: 'sign_out',
  //   value: 'completion',
  //   message: 'ログアウトしました。',
  //   type: 'success'
  // },
  {
    name: 'sign_in',
    value: 'already_signed_in',
    message: '既にログイン済です。',
    type: 'error'
  },
  {
    name: 'new_register',
    value: 'true',
    message: '会員登録が完了しました。',
    type: 'success'
  },
  {
    name: 'mj_account_locked',
    value: 'error',
    message: 'アカウントがロックされました。<a href="https://support.machicon.jp/hc/ja/requests/new?ticket_form_id=208718"  target="_blank">サポート</a>までお問い合わせください。',
    type: 'error'
  },
  {
    name: 'lbi_authentication',
    value: 'error',
    message: 'ログインできませんでした。<a href="https://support.machicon.jp/hc/ja/requests/new?ticket_form_id=208718"  target="_blank">サポート</a>までお問い合わせください。',
    type: 'error'
  },
  {
    name: 'lbi_registration',
    value: 'error',
    message: '会員登録に失敗しました。',
    type: 'error'
  },
  {
    name: 'mj_registration',
    value: 'force_deleted_user_error',
    message: '街コンジャパンを退会済みのためログインできません',
    type: 'error'
  },
  {
    name: 'changed_login_user',
    value: 'true',
    message: 'ログイン中のユーザが変わりました。ご確認ください。',
    type: 'success'
  },
  {
    name: 'received_ticket_success',
    value: 'true',
    message: 'チケットの受け取りが完了しました。',
    type: 'success'
  },
  {
    name: 'cl_konkatsu_freepass_invitation',
    value: 'invalid_session',
    message: 'セッション情報が無効です。',
    type: 'error'
  },
  {
    name: 'cl_konkatsu_freepass_invitation',
    value: 'unfilled_profile',
    message: '婚活フリーパスを受け取るには会員情報の登録が必要です。',
    type: 'error'
  },
  {
    name: 'cl_konkatsu_freepass_invitation',
    value: 'male',
    message: '男性は婚活フリーパスをご利用になれません。',
    type: 'error'
  },
  {
    name: 'cl_konkatsu_freepass_invitation',
    value: 'success',
    message: '婚活フリーパスが付与されました！対象イベントへご参加ください。',
    type: 'success'
  },
  {
    name: 'unfilled_profile',
    value: 'true',
    message: '購入するには会員情報の登録が必要です。',
    type: 'warning'
  },
  {
    name: 'unfilled_name',
    value: 'true',
    message: '購入するには名前の登録が必要です。',
    type: 'warning'
  },
  {
    name: 'fp_already_purchased',
    value: 'true',
    message: '既に婚活フリーパスを持っています。',
    type: 'error'
  },
  {
    name: 'mp_already_purchased',
    value: 'true',
    message: '既にメンズパスを持っています。',
    type: 'error'
  },
  {
    name: 'fp_invitation',
    value: 'male',
    message: '婚活フリーパスは、女性のみを対象としております。',
    type: 'error'
  },
  {
    name: 'mp_invitation',
    value: 'female',
    message: 'メンズパスは、男性のみを対象としております。',
    type: 'error'
  },
  {
    name: 'mp_invitation_unfilled_profile',
    value: 'true',
    message: 'メンズパスを受け取るには会員情報の登録が必要です。',
    type: 'error'
  },
  {
    name: 'fp_invitation_unfilled_profile',
    value: 'true',
    message: '婚活フリーパスを受け取るには会員情報の登録が必要です。',
    type: 'error'
  },
  {
    name: 'konkatsu_freepass',
    value: 'male',
    message: '男性会員は婚活フリーパスをご購入できません。',
    type: 'warning'
  },
  {
    name: 'already_purchased_event',
    value: 'true',
    message: '申し込み完了されているイベントです',
    type: 'warning'
  },
  // Amazon pay error message
  {
    name: 'error_code',
    value: 'existing_account_not_match_ticket',
    message: '年齢・性別が申し込みチケットの条件を満たしておりません。',
    type: 'error'
  },
  {
    name: 'error_code',
    value: 'amzpay_invalid_access_token',
    message: 'セッションが切れました。Amazonアカウントに再度ログインし、お試しください。',
    type: 'error'
  },
  {
    name: 'error_code',
    value: 'amzpay_unknown_error',
    message: 'エラーが発生しました。Amazonアカウントに再度ログインし、別の支払い方法をお試しください。',
    type: 'error'
  },
  // error codes from LBID
  {
    name: 'lbi_status_code',
    value: '10',
    message: DEFAULT_SYSTEM_ERROR_MSG,
    type: 'error'
  },
  {
    name: 'lbi_status_code',
    value: '20',
    message: DEFAULT_SYSTEM_ERROR_MSG,
    type: 'error'
  },
  {
    name: 'lbi_status_code',
    value: '30',
    message: 'このメールアドレスは既に登録されています。',
    type: 'error'
  },
  {
    name: 'lbi_status_code',
    value: '40',
    message: DEFAULT_SYSTEM_ERROR_MSG,
    type: 'error'
  }
]

// Handle flash messages from query params
// params: Query Parameters get from vue route
export const flashMessagesByQueryParams = params => {
  return Object.keys(params).map(key => {
    return LIST_QUERY_PARAMS.find(v => v.name === key && v.value === params[key])
  }).filter(v => v)
}
